import { Message } from '@api/interface/chatInterface';
import { MediaViewer } from '@components/basic/MediaViewer';
import useMediaQuery from '@hooks/useMediaQuery';
import { useAppSelector } from '@store/store';
import { FileType } from 'interface';
import { useEffect, useState } from 'react';
import { DEFAULT_AVATAR, themeColor } from 'utils/consts';

export const MessageItem = ({
  message,
  isMyMessage,
  partnerAvatar,
  showThought,
}: {
  message: Message;
  isMyMessage: boolean;
  partnerAvatar: string;
  showThought: boolean;
}) => {
  if (isMyMessage) {
    return <MyMessage message={message}></MyMessage>;
  }
  return (
    <OpMessage
      message={message}
      showThought={showThought}
      partnerAvatar={partnerAvatar}
    ></OpMessage>
  );
};

const MyMessage = ({ message }: { message: Message }) => {
  const userData = useAppSelector((state) => state.userData);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flexEnd',
        alignItems: 'flexStart',
        width: '100%',
        textAlign: 'end',
        marginTop: '10px',
        paddingRight: '12px',
      }}
    >
      <div style={{ flex: 1 }}></div>
      <div
        style={{
          padding: '10px',
          background: 'grey',
          borderRadius: '8px',
          marginRight: '8px',
          backgroundColor: themeColor.primary,
          color: 'white',
          fontSize: '1.1em',
          textAlign: 'left',
        }}
      >
        {message.text}
      </div>
      <img
        src={userData.avatar ? userData.avatar : DEFAULT_AVATAR}
        alt=""
        style={{
          width: '35px',
          height: '35px',
          borderRadius: '50%',
        }}
      ></img>
    </div>
  );
};

const judgement = (reliability: string, result: string) => {
  if (!reliability || !result) {
    return '';
  }
  if (result === 'unknown') {
    return '(can not make the judgement right now)';
  }
  return `(You are ${parseFloat(reliability) * 100}% likely ${
    result === 'human' ? 'an' : 'a'
  } ${result})`;
};

const OpMessage = ({
  message,
  showThought,
  partnerAvatar,
}: {
  message: Message;
  showThought: boolean;
  partnerAvatar: string;
}) => {
  const isDesktop = useMediaQuery('(min-width: 780px)');
  const [displayMessage, setDisplayMessage] = useState<string>(message.text);
  const [thought, setThought] = useState<string>('');
  useEffect(() => {
    if (displayMessage.includes('reply')) {
      try {
        const res = JSON.parse(displayMessage);
        setDisplayMessage(res?.reply);
      } catch (e) {
        setDisplayMessage('Hmmmmm....');
      }
    }
    if (displayMessage.includes('thought')) {
      try {
        const res = JSON.parse(displayMessage);
        let thought = res?.thought;
        if (res?.result && res?.reliability) {
          thought = `${thought}${judgement(res?.reliability, res?.result)}`;
        }
        setThought(thought);
      } catch (e) {
        setThought('interesting carbon-based life...');
      }
    }
  }, [displayMessage]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flexStart',
        alignItems: 'flexStart',
        width: '100%',
        marginTop: '10px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flexStart',
          alignItems: 'flexStart',
          width: '100%',
        }}
      >
        <img
          src={partnerAvatar ? partnerAvatar : DEFAULT_AVATAR}
          alt=""
          style={{
            width: '35px',
            height: '35px',
            borderRadius: '50%',
          }}
        ></img>
        <div
          style={{
            marginLeft: '8px',
            padding: '10px',
            maxWidth: '60%',
            backgroundColor: message.hitKnowledge ? 'orange' : 'grey',
            borderRadius: '8px',
            color: 'white',
            fontSize: '1.1em',
            border: message.hitKnowledge
              ? `3px solid ${themeColor.primary}`
              : undefined,
          }}
        >
          {displayMessage}
        </div>
      </div>
      {!!message?.kAttaches?.length && (
        <MediaViewer
          src={message.kAttaches?.[0]?.attachUrl}
          type={message.kAttaches?.[0]?.attachType}
          alt=""
          style={{
            width: isDesktop ? '350px' : '300px',
            marginLeft: '50px',
            marginTop: '10px',
            borderRadius: '20px',
          }}
        ></MediaViewer>
      )}
      {message.hitKnowledge && (
        <div
          style={{
            marginTop: '5px',
            color: 'orange',
            marginLeft: '50px',
            fontSize: '1.1em',
            maxWidth: isDesktop ? '400px' : '70%',
          }}
        >
          Echo disclosed
        </div>
      )}
      {!!thought && showThought && (
        <div
          style={{
            marginTop: '10px',
            marginLeft: '50px',
            fontSize: '1.1em',
            maxWidth: isDesktop ? '400px' : '70%',
          }}
        >
          AI thoughts:{thought}
        </div>
      )}
    </div>
  );
};
