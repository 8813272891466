import { AUTH_TOKEN_STORAGE_KET } from 'utils/consts';
import { turingAxios } from './base';
import { LoginReq, LoginResFlag, LoginRsp, NonceRsp } from './interface/auth';

// https://turingera.nfxcloud.cn/api/chat/NewChat
// axios.get();

export const login = async (loginReq: LoginReq): Promise<LoginResFlag> => {
  try {
    const res = await turingAxios.post('/auth/Login', loginReq);
    const data: LoginRsp = res?.data;
    localStorage.setItem(AUTH_TOKEN_STORAGE_KET, data.token);
    return data.resFlag;
  } catch (error) {
    return -1;
  }
};

export const getNonce = async (): Promise<string> => {
  try {
    const res = await turingAxios.post('/auth/Nonce');
    const data: NonceRsp = res?.data;
    return data.nonce;
  } catch (error) {
    return '';
  }
};
