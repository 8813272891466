import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from 'App';
import { Provider } from 'react-redux';
import store from 'store/store';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { themeColor } from 'utils/consts';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: themeColor.primaryStrong,
    },
    text: {
      // primary: themeColor.gray,
    },
  },
  typography: {
    fontFamily: 'space',
  },
});

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App></App>
    </ThemeProvider>
  </Provider>
);
