import styled from 'styled-components';
import { themeColor } from 'utils/consts';

const LoginButtonStyle = styled.button`
  border-radius: 20px;
  border-style: inset;
  outline: none;
  border-color: ${themeColor.primary};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  align-items: center;
  border-width: 3px;
  justify-content: center;
  color: white;
  font-size: 16px;
  padding: 15px 35px;
  background-color: ${themeColor.primary};
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
  &:hover {
    transform: translateY(-8px);
    transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  }
  &:active {
    transform: translateY(-2px);
    transition: transform 34ms;
  }
`;

export const LoginButton = ({
  text,
  src,
  onClick,
  style,
}: {
  text: string;
  src: string;
  onClick: Function;
  style?: React.CSSProperties;
}) => {
  return (
    <LoginButtonStyle onClick={() => onClick()} style={style}>
      <img
        src={src}
        alt=""
        style={{
          width: '18px',
          marginRight: '10px',
        }}
      />
      <div style={{ fontFamily: 'space' }}>{text}</div>
    </LoginButtonStyle>
  );
};
