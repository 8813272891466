import styled from 'styled-components';
import { themeColor } from 'utils/consts';

interface Props {
  isSelected: boolean;
}

const IconButtonStyle = styled.button<Props>`
  border: none;
  border-radius: 12px;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-items: center;
  justify-content: 'center';
  color: ${(prop) => (prop.isSelected ? themeColor.primary : 'white')};
  font-size: ${(prop) => (prop.isSelected ? '18px' : '16px')};
  font-family: space;
  padding: 5px 20px;
  background-color: transparent;
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
  &:hover {
    transform: translateY(-8px);
    transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  }
  &:active {
    transform: translateY(-2px);
    transition: transform 34ms;
  }
`;

export const IconButton = ({
  text,
  src,
  isSelected,
  onClick,
}: {
  text: string;
  src: string;
  isSelected: boolean;
  onClick: Function;
}) => {
  return (
    <IconButtonStyle isSelected={isSelected} onClick={() => onClick()}>
      <img
        src={src}
        alt=""
        style={{
          width: isSelected ? '20px' : '18px',
          marginBottom: '10px',
        }}
      />
      <div>{text}</div>
    </IconButtonStyle>
  );
};
