export enum LoginType {
  LOGIN_TYPE_UNKNOWN = 0,
  /** LOGIN_TYPE_GOOGLE - google */
  LOGIN_TYPE_GOOGLE = 1,
  /** LOGIN_TYPE_TWITTER - twitter */
  LOGIN_TYPE_TWITTER = 2,
  /** LOGIN_TYPE_WALLET - 钱包 */
  LOGIN_TYPE_WALLET = 3,
  UNRECOGNIZED = -1,
}

export enum LoginResFlag {
  LOGIN_SUCC = 0,
  /** LOGIN_ACCESS_CODE_EMPTY - 需要填写邀请码 */
  LOGIN_ACCESS_CODE_EMPTY = 1,
  /** LOGIN_ACCESS_CODE_USED - 邀请码已被使用 */
  LOGIN_ACCESS_CODE_USED = 2,
  UNRECOGNIZED = -1,
}

export interface EarlyAccess {
  earlyAccessCode: string;
  userId: string;
}

export interface NonceReq {}

export interface NonceRsp {
  nonce: string;
}

export interface LoginReq {
  accessToken?: string;
  secret?: string;
  /** 钱包登录使用 */
  message?: string;
  signature?: string;
  loginType?: number;
  earlyAccessCode?: string;
}

export interface LoginRsp {
  userId: string;
  token: string;
  expire: number;
  resFlag: number;
}

export interface Auth {
  /** 登录 */
  Login(request: LoginReq): Promise<LoginRsp>;
}
