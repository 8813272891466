import { turingAxios } from './base';
import { GetUserInfoRsp } from './interface/userInfo';

// https://turingera.nfxcloud.cn/api/chat/NewChat
// axios.get();

export const getUserInfo = async (): Promise<GetUserInfoRsp | undefined> => {
  try {
    const res = await turingAxios.post('/userinfo/GetUserInfo');
    return res?.data;
  } catch (error) {
    return undefined;
  }
};
