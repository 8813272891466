import { Fragment, useState } from 'react';
import CustomPopup from './PopUp';
import { useAppSelector } from '@store/store';
import { logout } from 'utils/auth';
import styled from 'styled-components';
import { DEFAULT_AVATAR, themeColor } from 'utils/consts';
import { useNavigate } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import { copyTextToClipboard } from 'utils';
import { vhs } from 'utils/vhs';
import { Button } from './basic/Button';
import Divider from '@mui/material/Divider';
import { useMediaQuery } from '@mui/material';

const TextContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  border-style: inset;
  border-width: 2px;
  margin: 10px 0;
  justify-content: start;
  border-color: ${themeColor.primary};
  font-family: space;
`;

const DescContainer = styled.div`
  overflow: hidden;
  overflow-wrap: break-word;
  display: flex;
  justify-content: flex-start;
  flex: 1;
`;

const Title = styled.div`
  margin-right: 10px;
  width: 130px;
  flex-direction: row;
  display: flex;
  justify-content: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PersonalCenter = ({ style }: { style: React.CSSProperties }) => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width: 780px)');
  const [showPopUp, setShowPopUp] = useState(false);
  const userData = useAppSelector((state) => state.userData);
  const signOut = () => {
    logout();
    navigate('/login');
  };
  return (
    <div
      style={{
        padding: '10px',
        borderRadius: '50px',
        borderStyle: 'inset',
        borderWidth: '2px',
        borderColor: themeColor.gray,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: themeColor.secondary,
        ...style,
      }}
    >
      <CustomPopup
        preventBubble={true}
        title={'Profile'}
        show={showPopUp}
        onClose={() => {
          setShowPopUp(false);
        }}
      >
        <TextContainer>
          <Title>Your ID</Title>
          <Divider orientation="vertical"></Divider>
          <DescContainer>{userData.userId}</DescContainer>
          <CopyOutlined
            style={{ color: 'white', marginTop: '5px', marginLeft: '10px' }}
            onClick={() => {
              copyTextToClipboard(userData.userId || '');
              vhs?.showToast({
                content: 'copied',
              });
            }}
          />
        </TextContainer>
        <TextContainer>
          <Title>
            {userData.loginType === 3 ? 'Wallet Address' : 'Your Name'}
          </Title>
          <Divider orientation="vertical"></Divider>
          <DescContainer>{userData.nick}</DescContainer>
        </TextContainer>
        {userData.email && (
          <TextContainer>
            <Title>Email</Title>
            <Divider orientation="vertical"></Divider>
            <DescContainer>{userData.email}</DescContainer>
          </TextContainer>
        )}
        <TextContainer>
          <Title style={{ color: themeColor.orange }}>Points</Title>
          <Divider orientation="vertical"></Divider>
          <DescContainer style={{ color: themeColor.orange }}>
            {userData.score}
          </DescContainer>
        </TextContainer>
        {userData.inviteCode && (
          <TextContainer>
            <Title>Invite Code</Title>
            <Divider orientation="vertical"></Divider>
            <DescContainer>{userData.inviteCode}</DescContainer>
            <CopyOutlined
              style={{ color: 'white', marginTop: '5px', marginLeft: '10px' }}
              onClick={() => {
                copyTextToClipboard(userData.inviteCode || '');
                vhs?.showToast({
                  content: 'copied',
                });
              }}
            />
          </TextContainer>
        )}

        <div style={{ height: '20px' }}></div>
        <Button onClick={() => signOut()}>Sign out</Button>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginTop: '20px',
          }}
        ></div>
      </CustomPopup>
      {isDesktop && (
        <Fragment>
          <img
            src="https://app.bot2.ai/bot2/fe-static/dollar.png"
            alt=""
            style={{
              width: '20px',
              height: '20px',
              marginRight: '10px',
            }}
          />
          <div
            style={{
              marginRight: '20px',
              color: themeColor.orange,
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
            }}
            onClick={(e) => {
              setShowPopUp(true);
            }}
          >
            <div> {userData.score} Points</div>
          </div>
        </Fragment>
      )}
      {/* 头像 */}
      <img
        src={userData.avatar ? userData.avatar : DEFAULT_AVATAR}
        alt=""
        style={{
          width: '40px',
          borderRadius: '50%',
        }}
        onClick={(e) => {
          setShowPopUp(true);
        }}
      />
    </div>
  );
};
