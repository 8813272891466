import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkAuthExist } from 'utils/auth';
export const AuthGuard = ({ component }: { component: React.ReactNode }) => {
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    checkToken();
  }, []);
  const checkToken = async () => {
    if (!checkAuthExist()) {
      navigate('/login');
      return;
    }
    setStatus(true);
  };

  return status ? <Fragment>{component}</Fragment> : <Fragment></Fragment>;
};
