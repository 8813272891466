import { AxiosError } from 'axios';
import { turingAxios } from './base';
import {
  DeleteRobotReq,
  DeleteRobotRsp,
  GetMyRobotsRsp,
  NewRobotReq,
  NewRobotRsp,
  RecommendRobotsRsp,
  GetRobotReq,
  GetRobotRsp,
  EditRobotReq,
  EditRobotRsp,
  GetEditOptionsRsp,
  RobotType,
  GetMyRobotsReq,
} from './interface/robertInterface';
import { vhs } from 'utils/vhs';

export const createNewRobotAPI = async (
  newRobot: NewRobotReq
): Promise<NewRobotRsp | undefined> => {
  try {
    const res = await turingAxios.post('/robot/NewRobot', newRobot);
    return res?.data;
  } catch (error: any) {
    if (error?.response?.data?.code === 13) {
      vhs.showToast({ content: 'You can only generate 5 robots at most' });
    }
  }
};

export const getRobotById = async (robotId: string): Promise<GetRobotRsp> => {
  const req: GetRobotReq = { robotId, needDetail: true };
  const res = await turingAxios.post('/robot/GetRobot', req);
  return res?.data;
};
export const editRobot = async (
  req: EditRobotReq
): Promise<EditRobotRsp | undefined> => {
  try {
    const res = await turingAxios.post('/robot/EditRobot', req);
    return res?.data;
  } catch (error) {}
};

export const deleteRobotAPI = async (
  deleteRobotReq: DeleteRobotReq
): Promise<DeleteRobotRsp | undefined> => {
  try {
    const res = await turingAxios.post('/robot/DeleteRobot', deleteRobotReq);
    return res?.data;
  } catch (error) {}
};

export const getMyRobotsAPI = async (
  botType: RobotType
): Promise<GetMyRobotsRsp | undefined> => {
  try {
    const req: GetMyRobotsReq = { botType };
    const res = await turingAxios.post('/robot/GetMyRobots', req);
    return res?.data;
  } catch (error) {}
};

export const getRecommendedRobotsAPI = async (): Promise<
  RecommendRobotsRsp | undefined
> => {
  try {
    const res = await turingAxios.post('/robot/RecommendRobots');
    return res?.data;
  } catch (error) {}
};

export const searchRobotAPI = async (
  id: string
): Promise<GetRobotRsp | undefined> => {
  try {
    const req: GetRobotReq = { robotId: id };
    const res = await turingAxios.post('/robot/GetRobot', req);
    return res?.data;
  } catch (error) {}
};

interface UploadResponse {
  Url: string;
}

export const uploadAvatar = async (
  formData: FormData
): Promise<UploadResponse | undefined> => {
  try {
    const res = await turingAxios.post('/upload/bot_avatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res?.data;
  } catch (error: any) {
    if (error?.response.status === 413) {
      vhs.showToast({
        content: 'Image too large, must be less than 800k',
        duration: 3000,
      });
      return;
    }
    vhs.showToast({ content: 'upload image failed ', duration: 3000 });
  }
};

export const getEditOptions = async (): Promise<GetEditOptionsRsp> => {
  const res = await turingAxios.post('/robot/GetEditOptions');
  return res?.data;
};
