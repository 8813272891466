import { KAttach } from './knowledgeInterface';

export enum UserType {
  USER_TYPE_HUMAN = 0,
  USER_TYPE_AI = 1,
  UNRECOGNIZED = -1,
}

export interface Message {
  id: string;
  user: string;
  createdAt?: number;
  text: string;
  /** 图片 */
  kAttaches?: KAttach[];
  hitKnowledge?: boolean;
  /** 愉悦度数值 */
  pleasure?: number;
}

export interface Info {
  chatId: string;
  chatTime?: number;
  userId?: string;
  createdAt?: number;
  numParticipants?: number;
  partnerId: string;
  partnerName: string;
  partnerAvatar: string;
  isMyTurn: boolean;
  turnTime: number;
  isActive: boolean;
  assign?: boolean;
  starter?: string;
  messages: Message[];
  guessed: boolean;
  correct: boolean;
  score: number;
  msgCount?: number;
  /** replica对话使用 */
  curNick?: string;
  curPlatform?: string;
  curLocation?: string;
  /** 知识库信息 */
  knowledgeCount?: number;
  knowledgeStatus?: string;
  hitKnowledgeCount?: number;
}
export interface ShareReq {
  chatId: string;
}

export interface ShareRsp {}

export interface GetUserIDReq {}

export interface GetUserIDRsp {
  userId: string;
}

export interface NewChatReq {
  /** 对话机器人ID，为空表示匹配 */
  robotId: string;
  nick?: string;
}

export interface NewChatRsp {
  info: Info | undefined;
}

export interface WaitMessageReq {
  chatId: string;
}

export interface WaitMessageRsp {
  info: Info | undefined;
}

export interface SendMessageReq {
  chatId: string;
  text: string;
  userId?: string;
}

export interface SendMessageRsp {
  info: Info | undefined;
}

export interface GuessReq {
  chatId: string;
  /** UserType */
  partnerType: number;
}

export interface GuessRsp {
  /** UserType */
  partnerType: number;
  correct: boolean;
  score: number;
}
export interface WaitGuessReq {
  chatId: string;
}

export interface WaitGuessRsp {
  info: Info | undefined;
}

export interface GetChatInfoReq {
  chatId: string;
}

export interface GetChatInfoRsp {
  info: Info | undefined;
}
export interface GetRobotChatHistoryReq {
  robotId: string;
  cookie: string;
}

export interface GetRobotChatHistoryRsp {
  infos: Info[];
  cookie: Uint8Array;
  isEnd: boolean;
}

export interface ChatSvr {
  /** 获取user_id */
  GetUserID(request: GetUserIDReq): Promise<GetUserIDRsp>;
  /** 新建对话 */
  NewChat(request: NewChatReq): Promise<NewChatRsp>;
  /** 等待消息 */
  WaitMessage(request: WaitMessageReq): Promise<WaitMessageRsp>;
  /** 发送消息 */
  SendMessage(request: SendMessageReq): Promise<SendMessageRsp>;
  /** 做出判断 */
  Guess(request: GuessReq): Promise<GuessRsp>;
  /** 获取对话详情 */
  GetChatInfo(request: GetChatInfoReq): Promise<GetChatInfoRsp>;
  /** 获取AI对话记录 */
  GetRobotChatHistory(
    request: GetRobotChatHistoryReq
  ): Promise<GetRobotChatHistoryRsp>;
  /** 等待判断 */
  WaitGuess(request: WaitGuessReq): Promise<WaitGuessRsp>;
  /** public新建对话 */
  PublicNewChat(request: NewChatReq): Promise<NewChatRsp>;
  /** public发送消息 */
  PublicSendMessage(request: SendMessageReq): Promise<SendMessageRsp>;
}
