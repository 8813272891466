import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';

import Chat from '@pages/chat/Chat';
import Home from '@pages/home/Home';
import { useAppDispatch } from 'store/store';
import { MyAIRepo } from '@pages/myBots/MyAIRepo';
import { BackgroundStory } from '@pages/home/BackgroundStory';
import { Search } from '@pages/search/Search';
import { Login } from '@pages/login/Login';
import { AuthGuard } from '@components/AuthGuard';
import { setupInterceptors, turingAxios } from '@api/base';
import { Fragment, lazy, Suspense, useEffect, useState } from 'react';
import { getUserInfo } from '@api/userInfoApi';
import { setUserData } from '@store/userDataSlice';
import '@rainbow-me/rainbowkit/styles.css';
import { RobotType } from '@api/interface/robertInterface';
import {
  darkTheme,
  getDefaultWallets,
  RainbowKitAuthenticationProvider,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { mainnet, polygon, optimism, arbitrum } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { authenticationAdapter } from 'utils/walletAuth';
import { AUTH_TOKEN_STORAGE_KET, themeColor } from 'utils/consts';
const AboutMePage = lazy(() => import('@pages/replica/generation/AboutMePage'));
const Leaderboard = lazy(() => import('@pages/leaderboard/Index'));
const ChatDetail = lazy(() => import('@pages/replica/chatHistory/ChatDetail'));
const EchoPage = lazy(() => import('@pages/replica/generation/EchoPage'));
const ReplicaDetail = lazy(() => import('@pages/replica/detail/ReplicaDetail'));
const ReplicaGeneration = lazy(
  () => import('@pages/replica/generation/ReplicaGeneration')
);
const GenerationPage = lazy(() => import('@pages/generateNew/GenerationPage'));
const ReplicaChat = lazy(() => import('@pages/replica/chat/ReplicaChat'));
const ChatHistory = lazy(
  () => import('@pages/replica/chatHistory/ChatHistory')
);
const ReplicaHome = lazy(() => import('@pages/replica/ReplicaHome'));
const ReplicaIntro = lazy(() => import('@pages/replicaIntro/ReplicaIntro'));

const { chains, publicClient } = configureChains(
  [mainnet, polygon, optimism, arbitrum],
  [alchemyProvider({ apiKey: process.env.ALCHEMY_ID || '' }), publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'Bot2',
  projectId: '91465c23db5e225d16bc878d2844f992',
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login></Login>,
  },
  {
    path: '/chat/:id',
    element: <AuthGuard component={<Chat></Chat>} />,
  },
  {
    path: '/chat',
    element: <AuthGuard component={<Chat></Chat>} />,
  },
  {
    path: '/leaderboard',
    element: <AuthGuard component={<Leaderboard></Leaderboard>} />,
  },
  {
    path: '/generate/:id',
    element: <AuthGuard component={<GenerationPage></GenerationPage>} />,
  },
  {
    path: '/generate',
    element: <AuthGuard component={<GenerationPage></GenerationPage>} />,
  },
  {
    path: '/replica/generate',
    element: <AuthGuard component={<ReplicaGeneration></ReplicaGeneration>} />,
  },
  {
    path: '/replica/generate/:id',
    element: (
      <AuthGuard
        component={
          <GenerationPage type={RobotType.ROBOT_TYPE_REPLICA}></GenerationPage>
        }
      />
    ),
  },
  {
    path: '/replica/intro',
    element: <AuthGuard component={<ReplicaIntro></ReplicaIntro>} />,
  },
  {
    path: '/replica-chat/:id',
    element: <ReplicaChat />,
  },
  {
    path: '/replica/knowledge',
    element: <AuthGuard component={<EchoPage></EchoPage>} />,
  },
  {
    path: '/replica/aboutMe',
    element: <AuthGuard component={<AboutMePage></AboutMePage>} />,
  },
  {
    path: '/replica',
    element: <AuthGuard component={<ReplicaHome></ReplicaHome>} />,
    children: [
      {
        path: '/replica',
        element: <AuthGuard component={<ReplicaDetail></ReplicaDetail>} />,
      },
      {
        path: '/replica/detail',
        element: <AuthGuard component={<ReplicaDetail></ReplicaDetail>} />,
      },
      {
        path: '/replica/chat-history',
        element: <AuthGuard component={<ChatHistory></ChatHistory>} />,
      },
      {
        path: '/replica/chat-detail/:id',
        element: <AuthGuard component={<ChatDetail></ChatDetail>} />,
      },
    ],
  },
  {
    path: '/',
    element: <AuthGuard component={<Home></Home>} />,
    children: [
      {
        path: '/home',
        element: <AuthGuard component={<BackgroundStory></BackgroundStory>} />,
      },
      {
        path: '/mine',
        element: <AuthGuard component={<MyAIRepo></MyAIRepo>} />,
      },
      {
        path: '/search',
        element: <AuthGuard component={<Search></Search>} />,
      },
    ],
  },
]);

export const App = () => {
  const dispatch = useAppDispatch();
  const [isLogin, setIsLogin] = useState(false);
  useEffect(() => {
    function checkIsLogin() {
      const item = localStorage.getItem(AUTH_TOKEN_STORAGE_KET);

      if (item) {
        setIsLogin(!!item);
      }
    }

    window.addEventListener('storage', checkIsLogin);

    return () => {
      window.removeEventListener('storage', checkIsLogin);
    };
  }, []);
  const getUser = async () => {
    const userInfo = await getUserInfo();
    dispatch(
      setUserData({
        nick: userInfo?.user?.nick || '',
        avatar: userInfo?.user?.avatar || '',
        email: userInfo?.user?.email || '',
        userId: userInfo?.user?.userId || '',
        loginType: userInfo?.user?.loginType || 0,
        score: userInfo?.user?.dynamic?.score || 0,
        inviteCode: userInfo?.user?.inviteCode || '',
      })
    );
  };

  useEffect(() => {
    setupInterceptors(turingAxios);
    getUser();
  }, []);
  return (
    <Fragment>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitAuthenticationProvider
          adapter={authenticationAdapter}
          status={isLogin ? 'authenticated' : 'unauthenticated'}
        >
          <RainbowKitProvider
            chains={chains}
            theme={darkTheme({
              accentColor: `${themeColor.primary}`,
              accentColorForeground: 'white',
              borderRadius: 'medium',
              fontStack: 'rounded',
              overlayBlur: 'large',
            })}
          >
            <Suspense fallback={'Loading...'}>
              <RouterProvider router={router} />
            </Suspense>
          </RainbowKitProvider>
        </RainbowKitAuthenticationProvider>
      </WagmiConfig>
    </Fragment>
  );
};
