import { turingAxios } from './base';
import {
  GetChatInfoReq,
  GetChatInfoRsp,
  GetRobotChatHistoryReq,
  GetRobotChatHistoryRsp,
  GetUserIDRsp,
  GuessReq,
  GuessRsp,
  NewChatReq,
  NewChatRsp,
  SendMessageReq,
  SendMessageRsp,
  ShareReq,
  WaitGuessReq,
  WaitGuessRsp,
  WaitMessageReq,
  WaitMessageRsp,
} from './interface/chatInterface';

// https://turingera.nfxcloud.cn/api/chat/NewChat
// axios.get();

export const createNewChat = async (
  newChatReq: NewChatReq
): Promise<NewChatRsp | undefined> => {
  try {
    const res = await turingAxios.post('/chat/NewChat', newChatReq);
    return res?.data;
  } catch (error) {}
};

export const publicCreateNewChat = async (
  newChatReq: NewChatReq
): Promise<NewChatRsp | undefined> => {
  try {
    const res = await turingAxios.post('/public/chat/NewChat', newChatReq);
    return res?.data;
  } catch (error) {}
};

export const sendMessageAPI = async (
  sendMessageReq: SendMessageReq
): Promise<SendMessageRsp | undefined> => {
  try {
    const res = await turingAxios.post('/chat/SendMessage', sendMessageReq);
    return res?.data;
  } catch (error) {}
};

export const publicSendMessageAPI = async (
  sendMessageReq: SendMessageReq
): Promise<SendMessageRsp | undefined> => {
  try {
    const res = await turingAxios.post(
      '/public/chat/SendMessage',
      sendMessageReq
    );
    return res?.data;
  } catch (error) {}
};

export const waitMessageAPI = async (
  waitMessageReq: WaitMessageReq
): Promise<WaitMessageRsp | undefined> => {
  try {
    const res = await turingAxios.post('/chat/WaitMessage', waitMessageReq);
    return res?.data;
  } catch (error) {}
};

export const guessAPI = async (
  sendMessageReq: GuessReq
): Promise<GuessRsp | undefined> => {
  try {
    const res = await turingAxios.post('/chat/Guess', sendMessageReq);
    return res?.data;
  } catch (error) {}
};

export const getUserIdAPI = async (): Promise<GetUserIDRsp | undefined> => {
  try {
    const res = await turingAxios.post('/chat/GetUserID');
    return res?.data;
  } catch (error) {}
};

export const waitGuessAPI = async (
  chatId: string
): Promise<WaitGuessRsp | undefined> => {
  const req: WaitGuessReq = { chatId };
  try {
    const res = await turingAxios.post('/chat/WaitGuess', req);
    return res?.data;
  } catch (error) {}
};

export const shareAndEarn = async (chatId: string): Promise<boolean> => {
  const req: ShareReq = { chatId };
  try {
    await turingAxios.post('/chat/Share', req);
    return true;
  } catch (error) {
    return false;
  }
};

export const getChatHistory = async (
  req: GetRobotChatHistoryReq
): Promise<GetRobotChatHistoryRsp> => {
  const res = await turingAxios.post('/chat/GetRobotChatHistory', req);
  return res?.data;
};

export const getChatInfo = async (chatId: string): Promise<GetChatInfoRsp> => {
  const req: GetChatInfoReq = { chatId };
  const res = await turingAxios.post('/chat/GetChatInfo', req);
  return res?.data;
};
