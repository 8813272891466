export const shareOnTwitterWithWin = () => {
  window.open(
    'https://twitter.com/intent/tweet?url=http://app.bot2.com&text=I%20have%20earned%20x%20points%20from%20Bot2,%20join%20and%20earn%20points%20by%20joining%20Bot2%20%20',
    '_blank'
  );
};

export const shareOnTwitter = () => {
  window.open(
    'https://twitter.com/intent/tweet?url=http://app.bot2.com&text=Earn%20points%20by%20joining%20Bot2%20%20',
    '_blank'
  );
};
