import { getNonce, login } from '@api/authApi';
import { LoginResFlag, LoginType } from '@api/interface/auth';
import { createAuthenticationAdapter } from '@rainbow-me/rainbowkit';
import { SiweMessage } from 'siwe';
import { logout } from './auth';
import { vhs } from './vhs';
export const authenticationAdapter = createAuthenticationAdapter({
  getNonce: async () => {
    const nonce = await getNonce();
    return nonce;
  },
  createMessage: ({ nonce, address, chainId }) => {
    return new SiweMessage({
      domain: window.location.host,
      address,
      statement: 'Sign in with Ethereum to the app.',
      uri: window.location.origin,
      version: '1',
      chainId,
      nonce,
    });
  },
  getMessageBody: ({ message }) => {
    return message.prepareMessage();
  },
  verify: async ({ message, signature }) => {
    const loginRes = await login({
      signature: signature,
      message: message.prepareMessage(),
      loginType: LoginType.LOGIN_TYPE_WALLET,
    });

    if (loginRes === LoginResFlag.LOGIN_SUCC) {
      if (window.location.pathname !== '/home') {
        window.location.replace(`${window.origin}/home`);
      }
      return true;
    }

    if (loginRes === LoginResFlag.LOGIN_ACCESS_CODE_EMPTY) {
      document
        .querySelectorAll('[aria-label="Close"]')
        // @ts-ignore
        .forEach((el) => el?.click());
      const myPromise = new Promise((resolve, reject) => {
        vhs.showModal({
          title: 'please Input early access code',
          content: 'content',
          hasInput: true,
          cancelTitle: 'Cancel',
          cancel: () => {
            reject();
          },
          confirmTitle: 'Confirm',
          confirm: async (earlyAccess: string) => {
            const loginRes = await login({
              signature: signature,
              message: message.prepareMessage(),
              loginType: LoginType.LOGIN_TYPE_WALLET,
              earlyAccessCode: earlyAccess,
            });
            resolve(loginRes);
          },
        });
      });

      const res = await myPromise;
      if (window.location.pathname !== '/home') {
        window.location.replace(`${window.origin}/home`);
      }
      return Boolean(res);
    }
    return false;
  },
  signOut: async () => {
    logout();
    return;
  },
});
