import { CopyOutlined } from '@ant-design/icons';
import { useMediaQuery } from '@mui/material';
import { copyTextToClipboard } from 'utils';
import { DEFAULT_AVATAR, themeColor } from 'utils/consts';
import { vhs } from 'utils/vhs';

export const BotHorizontalCard = ({
  avatar,
  id,
  name,
}: {
  avatar: string;
  id: string;
  name: string;
}) => {
  const isDesktop = useMediaQuery('(min-width: 780px)');
  return (
    <div
      style={{
        width: isDesktop ? '400px' : '100%',
        position: 'relative',
        fontSize: '14px',
        padding: '13px',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderRadius: '8px',
        margin: '15px 0',
        display: 'flex',
        flexDirection: 'row',
        borderColor: themeColor.primary,
        boxShadow: `2px 2px 9px 3px ${themeColor.secondary}`,
        background: `linear-gradient(70deg, ${themeColor.orange}, ${themeColor.secondary} 98%)`,
      }}
    >
      {/* avatar */}
      <img
        src={avatar ? avatar : DEFAULT_AVATAR}
        alt=""
        style={{ width: '80px', height: '80px', borderRadius: '20px' }}
      ></img>

      {/* info */}
      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyItems: 'flex-start',
            alignItems: 'center',
            marginTop: '5px',
            fontSize: '18px',
          }}
        >
          <div>{name}</div>
        </div>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyItems: 'flex-start',
            alignItems: 'center',
            fontSize: '12px',
          }}
        >
          <div>{id}</div>
          <div style={{ flex: 1 }}></div>
          <CopyOutlined
            style={{ marginLeft: '10px' }}
            onClick={() => {
              copyTextToClipboard(id || '');
              vhs?.showToast({
                content: 'copied',
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
