import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { logOnDev } from 'utils';
import { AUTH_TOKEN_STORAGE_KET } from 'utils/consts';

// https://turingera.nfxcloud.cn/api/chat/NewChat
// axios.get();

const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KET);

export const turingAxios = axios.create({
  baseURL: 'https://bot2.ai/api',
  timeout: 60000,
  responseType: 'json',
  proxy: {
    protocol: 'https',
    host: '127.0.0.1',
    port: 9000,
  },
  headers: {
    Authorization: `Bearer ${token}`, // token is setup in every request
  },
});

// Request Interceptor
const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const { method, url } = config;
  const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KET);
  config.headers['Authorization'] = `Bearer ${token}`;
  logOnDev(`🚀 [API] ${method?.toUpperCase()} ${url} | Request`);
  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  const { method, url } = response.config;
  const { status } = response;
  // Set Loading End Here
  // Handle Response Data Here
  // Error Handling When Return Success with Error Code Here
  logOnDev(`🚀 [API] ${method?.toUpperCase()} ${url} | Response ${status}`);

  return response;
};

const onErrorResponse = (error: AxiosError | Error): Promise<AxiosError> => {
  if (axios.isAxiosError(error)) {
    const { message } = error;
    const { method, url } = error.config as AxiosRequestConfig;
    console.log('url: ', url);
    const { statusText, status } = (error.response as AxiosResponse) ?? {};

    logOnDev(
      `🚨 [API] ${method?.toUpperCase()} ${url} | Error ${status} ${message}`
    );

    switch (status) {
      case 401: {
        // "Login required"
        break;
      }
      case 403: {
        // "Permission denied"
        break;
      }
      case 404: {
        // "Invalid request"
        break;
      }
      case 500: {
        // "Server error"
        break;
      }
      default: {
        // "Unknown error occurred"
        break;
      }
    }

    if (status === 401) {
      if (
        url === '/userinfo/GetUserInfo' &&
        window.location.pathname.split('/')?.[1] === 'replica-chat'
      ) {
        console.log('special');
      } else {
        // Delete Token & Go To Login Page if you required.
        localStorage.removeItem(AUTH_TOKEN_STORAGE_KET);
        if (window.location.pathname !== '/login') {
          window.location.replace(`${window.origin}/login`);
        }
      }
    }
  } else {
    logOnDev(`🚨 [API] | Error ${error.message}`);
  }

  return Promise.reject(error);
};

export const setupInterceptors = (instance: AxiosInstance): AxiosInstance => {
  instance.interceptors.request.use(onRequest, onErrorResponse);
  instance.interceptors.response.use(onResponse, onErrorResponse);

  return instance;
};
