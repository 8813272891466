import { SetStateAction, useEffect, useRef, useState } from 'react';
import './chat.css';
import { DEFAULT_AVATAR, themeColor } from 'utils/consts';
import styled from 'styled-components';
import { SmallButton } from '@components/basic/SmallButton';
export const TextInput = ({
  isMyTurn,
  sendMessage,
  partnerAvatar,
}: {
  isMyTurn: boolean;
  sendMessage: Function;
  partnerAvatar: string;
}) => {
  if (isMyTurn) {
    return <MyInput sendMessage={sendMessage}></MyInput>;
  }
  return <OpInputting partnerAvatar={partnerAvatar}></OpInputting>;
};

const Input = styled.input`
  padding: 20px 10px;
  margin-right: 10px;
  width: 200px;
  height: 25px;
  background-color: ${themeColor.primary};
  border-radius: 10px;
  border-style: none;
  position: relative;
  transition: width 0.4s ease-in-out;
  color: white;
  font-size: '1.2em';
  &:focus {
    color: 'white';
    border-radius: 10px;
    border-width: 2px;
    border-style: inset;
    border-color: ${themeColor.secondary};
    outline: none;
  }
`;

const MyInput = ({ sendMessage }: { sendMessage: Function }) => {
  const [value, setValue] = useState<string>();
  const handleChange = (event: {
    target: { value: SetStateAction<string | undefined> };
  }) => {
    setValue(event.target.value);
  };
  const inputRef: React.Ref<any> = useRef(null);
  const handleKeyDown = (e: { keyCode: number; key: string }) => {
    if (e.keyCode === 13) {
      sendMessage(value);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      inputRef?.current?.focus();
      inputRef?.current?.click();
    });
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flexEnd',
        alignItems: 'center',
        width: '100%',
        textAlign: 'end',
        marginTop: '10px',
        marginRight: '12px',
      }}
    >
      <div style={{ flex: 1 }}></div>

      <Input
        ref={inputRef}
        autoFocus
        type="text"
        enterKeyHint="send"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <SmallButton
        onClick={() => {
          sendMessage(value);
        }}
      >
        Send
      </SmallButton>
    </div>
  );
};

const OpInputting = ({ partnerAvatar }: { partnerAvatar: string }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flexStart',
        alignItems: 'flexStart',
        width: '100%',
        marginTop: '10px',
      }}
    >
      <img
        src={partnerAvatar ? partnerAvatar : DEFAULT_AVATAR}
        alt=""
        style={{
          width: '35px',
          height: '35px',
          borderRadius: '50%',
        }}
      ></img>
      <div
        style={{
          marginLeft: '8px',
          padding: '10px',
          maxWidth: '60%',
          backgroundColor: 'grey',
          borderRadius: '8px',
          color: 'white',
        }}
      >
        Typing.....................
      </div>
    </div>
  );
};
