export interface Identity {
  id?: string;
  role: string;
  desc: string;
}
export enum RobotType {
  ROBOT_TYPE_UNKNOWN = 0,
  ROBOT_TYPE_NORMAL = 1,
  ROBOT_TYPE_REPLICA = 2,
  UNRECOGNIZED = -1,
}

export interface Gender {
  id: number;
  nameEn: string;
}

export interface RobotBasic {
  robotId?: string;
  userId?: string;
  name: string;
  avatar: string;
  /** RobotType */
  botType?: number;
  location: string;
  createTime?: number;
}

export interface SubDialogueStyle {
  cate: number;
  subStyle: string;
}

export interface DialogueStyle {
  id: string;
  main: string;
  subs: SubDialogueStyle[];
}

export interface RobotCore {
  /** 年龄 */
  age: number;
  /** 性别 */
  gender: Gender | undefined;
  /** 身份 */
  identity: Identity | undefined;
  /** 对话风格 */
  dialogueStyles: DialogueStyle[];
}

export interface RobotDynamic {
  /** 积分 */
  score: number;
  /** 总场数 */
  gameTotal: number;
  /** 胜利数 */
  gameWin: number;
}

export interface Robot {
  basic: RobotBasic | undefined;
  core: RobotCore | undefined;
  dynamic?: RobotDynamic | undefined;
}

export interface MoodValue {
  joy: number;
  fear: number;
  trust: number;
  surprise: number;
}

export interface PersonalityValue {
  positive: number;
  peaceful: number;
  open: number;
  extravert: number;
}

export interface Personality {
  characterTraits?: string[];
  mood: MoodValue;
  personality: PersonalityValue;
}

export interface Phrase {
  phrase: string;
  meaning: string;
  example: string;
}

export interface PhraseComb {
  id?: string;
  name?: string;
  rows: Phrase[];
}

export interface Experience {
  id: string;
  question: string;
  answer: string;
}

export interface RobotDetail {
  robotId?: string;
  personality: Personality | undefined;
  phrase: PhraseComb | undefined;
  experiences: Experience[];
  /** 是否使用自定义知识库 */
  useKnowledge?: boolean;
}

export interface GetEditOptionsReq {}

export interface GetEditOptionsRsp {
  genders: Gender[];
  identities: Identity[];
  dialogueStyles: DialogueStyle[];
  phraseComb: PhraseComb[];
  experiences: Experience[];
}

export interface GetRobotReq {
  robotId: string;
  needDetail?: boolean;
}

export interface GetRobotRsp {
  robot: Robot | undefined;
  detail: RobotDetail | undefined;
}

export interface NewRobotReq {
  robot: Robot | undefined;
  detail: RobotDetail | undefined;
}

export interface NewRobotRsp {
  robot: Robot | undefined;
  detail: RobotDetail | undefined;
}

export interface EditRobotReq {
  robot: Robot | undefined;
  detail: RobotDetail | undefined;
}

export interface EditRobotRsp {
  robot: Robot | undefined;
  detail: RobotDetail | undefined;
}

export interface DeleteRobotReq {
  robotId: string;
}

export interface DeleteRobotRsp {}

export interface GetMyRobotsReq {
  /** RobotType */
  botType: number;
}

export interface GetMyRobotsRsp {
  robots: Robot[];
}

export interface RecommendRobotsReq {}

export interface RecommendRobotsRsp {
  robots: Robot[];
}

export interface RandomRobotIDReq {}

export interface RandomRobotIDRsp {
  robotId: string;
}

export interface UpdateDynamicReq {
  robotId: string;
  dynamic: RobotDynamic | undefined;
}

export interface UpdateDynamicRsp {}

export interface UpdateUseKnowledgeReq {
  robotId: string;
  useKnowledge: boolean;
}

export interface UpdateUseKnowledgeRsp {}

export interface RobotSvr {
  /** 获取编辑选项 */
  GetEditOptions(request: GetEditOptionsReq): Promise<GetEditOptionsRsp>;
  /** 获取AI信息 */
  GetRobot(request: GetRobotReq): Promise<GetRobotRsp>;
  /** 新建AI */
  NewRobot(request: NewRobotReq): Promise<NewRobotRsp>;
  /** 编辑AI */
  EditRobot(request: EditRobotReq): Promise<EditRobotRsp>;
  /** 删除AI */
  DeleteRobot(request: DeleteRobotReq): Promise<DeleteRobotRsp>;
  /** 获取我的AI列表 */
  GetMyRobots(request: GetMyRobotsReq): Promise<GetMyRobotsRsp>;
  /** 推荐AI列表 */
  RecommendRobots(request: RecommendRobotsReq): Promise<RecommendRobotsRsp>;
  /** 随机AI */
  RandomRobotID(request: RandomRobotIDReq): Promise<RandomRobotIDRsp>;
  /** 更新动态数据 */
  UpdateDynamic(request: UpdateDynamicReq): Promise<UpdateDynamicRsp>;
  /** 更新是否使用知识库 */
  UpdateUseKnowledge(
    request: UpdateUseKnowledgeReq
  ): Promise<UpdateUseKnowledgeRsp>;
}
