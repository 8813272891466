export enum KnowledgeType {
  K_TYPE_ECHO = 0,
  K_TYPE_ABOUT_ME = 1,
  UNRECOGNIZED = -1,
}

export enum KAttachType {
  k_ATTACH_TYPE_PIC = 0,
  k_ATTACH_TYPE_VIDEO = 1,
  UNRECOGNIZED = -1,
}

export interface KnowledgeInfo {
  kId?: string;
  userId?: string;
  robotId?: string;
  text: string;
  createTime?: number;
  kAttaches?: KAttach[];
}

export interface KAttach {
  /** KAttachType */
  attachType: number;
  attachUrl: string;
}
export interface GetKnowledgeListReq {
  robotId: string;
  kType: number;
}

export interface GetKnowledgeListRsp {
  infos: KnowledgeInfo[];
  knowledgeStatus: string;
}

export interface SaveKnowledgeReq {
  info: KnowledgeInfo | undefined;
}

export interface SaveKnowledgeRsp {
  info: KnowledgeInfo | undefined;
}

export interface RemKnowledgeReq {
  robotId: string;
  kId: string;
}

export interface RemKnowledgeRsp {}

export interface LoadKnowledgeReq {
  robotId: string;
}

export interface LoadKnowledgeRsp {}

export interface SearchKnowledgeReq {
  robotId: string;
  text: string;
  kType: number;
  topN: number;
}

export interface SearchKnowledgeRsp {
  infos: KnowledgeInfo[];
}

export interface SaveAllKnowledgeReq {
  robotId: string;
  kType: number;
  infos: KnowledgeInfo[];
  knowledgeStatus?: string;
}

export interface SaveAllKnowledgeRsp {
  infos: KnowledgeInfo[];
  knowledgeStatus: string;
}

export interface GetRobotKnowledgeInfoReq {
  robotId: string;
  kType: number;
}

export interface GetRobotKnowledgeInfoRsp {
  knowledgeCount: number;
  knowledgeStatus: string;
}

export interface KnowledgeSvr {
  /** 获取知识列表 */
  GetKnowledgeList(request: GetKnowledgeListReq): Promise<GetKnowledgeListRsp>;
  /** 保存知识资料 */
  SaveKnowledge(request: SaveKnowledgeReq): Promise<SaveKnowledgeRsp>;
  /** 删除知识资料 */
  RemKnowledge(request: RemKnowledgeReq): Promise<RemKnowledgeRsp>;
  /** 加载知识库 */
  LoadKnowledge(request: LoadKnowledgeReq): Promise<LoadKnowledgeRsp>;
  /** 搜索知识库 */
  SearchKnowledge(request: SearchKnowledgeReq): Promise<SearchKnowledgeRsp>;
  /** 保存前部知识资料 */
  SaveAllKnowledge(request: SaveAllKnowledgeReq): Promise<SaveAllKnowledgeRsp>;
  /** 获取AI知识库信息 */
  GetRobotKnowledgeInfo(
    request: GetRobotKnowledgeInfoReq
  ): Promise<GetRobotKnowledgeInfoRsp>;
}
