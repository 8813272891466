import styled from 'styled-components';
import { themeColor } from 'utils/consts';
import { SmallButton } from './basic/SmallButton';
import { Typography, Variant } from './basic/Typography';
import useMediaQuery from '@hooks/useMediaQuery';

const HeaderContainer = styled.div`
  z-index: 20;
  width: 100%;
  background: linear-gradient(
    0deg,
    ${themeColor.background},
    ${themeColor.primary} 90%
  );
  height: 80px;
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Button = styled(SmallButton)`
  position: absolute;
  left: 20px;
`;

const StyledArrow = styled.div`
  width: 15px;
  height: 15px;
  border-top: 4px solid #ffffff;
  border-left: 4px solid #ffffff;
  transform: rotate(-45deg);
  position: absolute;
  left: 20px;
  cursor: pointer;
`;

export const Header = ({
  title,
  onBack,
  style,
}: {
  title: string;
  onBack?: Function;
  style?: React.CSSProperties;
}) => {
  const isDesktop = useMediaQuery('(min-width: 780px)');

  return (
    <HeaderContainer style={style}>
      {!onBack ? (
        <span></span>
      ) : isDesktop ? (
        <Button onClick={onBack}>Back</Button>
      ) : (
        <StyledArrow onClick={() => onBack()}></StyledArrow>
      )}

      <Typography variant={Variant.H1}>{title}</Typography>
    </HeaderContainer>
  );
};
