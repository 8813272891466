import { CloseCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { Robot } from '@api/interface/robertInterface';
import { useNavigate } from 'react-router-dom';
import { copyTextToClipboard } from 'utils';
import { DEFAULT_AVATAR, themeColor } from 'utils/consts';
import { vhs } from 'utils/vhs';
import styled from 'styled-components';
import { useMediaQuery } from '@mui/material';

export const buttonStyle: React.CSSProperties = {
  fontSize: '14px',
  fontWeight: 'bold',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  padding: '10px',
  color: themeColor.primary,
  borderStyle: 'inset',
  borderWidth: '1px',
  borderColor: 'black',
  marginRight: '10px',
  backgroundColor: themeColor.orange,
  fontFamily: 'space',
  cursor: 'pointer',
};

const ChatButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 10px;
  color: ${themeColor.primary};
  border-style: solid;
  border-width: 2px;
  border-color: ${themeColor.primary};
  background-color: ${themeColor.orange};
  &:hover {
    transform: scale(1.04);
  }
  &:active {
    transform: scale(1);
  }
`;

export const BotItem = ({
  item,
  deleteAI,
  editAI,
}: {
  item: Robot;
  deleteAI?: Function;
  editAI?: Function;
}) => {
  const isDesktop = useMediaQuery('(min-width: 780px)');
  const navigate = useNavigate();
  return (
    <div
      key={item.basic?.robotId}
      style={{
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        width: isDesktop ? '250px' : '90%',
        borderRadius: '30px',
        borderStyle: 'inset',
        borderWidth: '1px',
        borderColor: themeColor.primary,
        boxShadow: `2px 2px 9px 3px ${themeColor.secondary}`,
        background: `linear-gradient(70deg, ${themeColor.orange}, ${themeColor.secondary} 98%)`,
      }}
    >
      {!!deleteAI && (
        <div
          style={{
            position: 'absolute',
            right: '5px',
            top: '5px',
          }}
        >
          <CloseCircleOutlined
            onClick={() => deleteAI?.()}
            style={{
              color: themeColor.orange,
              fontSize: '20px',
              transform: 'translate(50%, -50%)',
            }}
          />
        </div>
      )}
      {/* avatar */}
      <img
        src={item?.basic?.avatar ? item?.basic?.avatar : DEFAULT_AVATAR}
        style={{
          width: '90%',
          // height: '230px',
          borderRadius: '30px',
          marginTop: '10px',
        }}
        alt=""
      ></img>
      {/* info */}
      <div
        style={{
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'flex-start',
          margin: '10px',
          marginBottom: '30px',
          textShadow: '3px 3px 3px rgb(168, 68, 69)',
        }}
      >
        <div>{item.basic?.name}</div>
        <div
          style={{
            width: '250px',
            fontSize: '12px',
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            textShadow: '3px 3px 3px rgb(168, 68, 69)',
          }}
        >
          Points Earned: {item.dynamic?.score || 0}
        </div>
        <div
          style={{
            width: '250px',
            fontSize: '12px',
            margin: '10px 0',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            textShadow: '3px 3px 3px rgb(168, 68, 69)',
          }}
        >
          <div> ID: {item.basic?.robotId}</div>
          <CopyOutlined
            style={{ color: 'white', marginLeft: '6px' }}
            onClick={() => {
              copyTextToClipboard(item.basic?.robotId || '');
              vhs?.showToast({
                content: 'copied',
              });
            }}
          />
        </div>
        <ChatButton
          onClick={() => {
            navigate(`/chat/${item.basic?.robotId}`);
          }}
        >
          Chat
        </ChatButton>

        {editAI && (
          <ChatButton
            style={{
              marginTop: '10px',
            }}
            onClick={() => editAI()}
          >
            Edit
          </ChatButton>
        )}
      </div>
    </div>
  );
};
