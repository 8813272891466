export const maxWidth = '780px';
export const standardWidth = '700px';

export const themeColor = {
  primaryStrong: '#b52e98',
  primary: '#7B2969', // rgb(123, 41, 105)
  primaryFade: 'rgb(123, 41, 105,0.6)',
  primaryFadeMore: 'rgb(123, 41, 105,0.3)',
  background: 'rgb(25, 25, 30,0.5)',
  darkPink: 'rgb(35,28,61)',
  lightPink: 'rgb(153,121,251)',
  secondary: '#471D4B', //rgb(71,29,75)
  orange: '#E27746',
  orangeFade: '#E2774611',
  gray: 'rgb(77,74,78)',
  white: '#ffffff',
  red: 'rgb(181,52,29)',
  blue: 'rgb(88,154,221)',
};

export const textShadow = '2px 2px 0px #8a5f1c';

export const cities = [
  'Shanghai',
  'Los Angeles',
  'Brooklyn',
  'Melbourne',
  'New York',
  'Tokyo',
  'California',
  'Bangkok',
  'Singapore',
  'Argentina',
  'Beijing',
  'Sydney',
  'London',
];

export const AUTH_TOKEN_STORAGE_KET = 'USER_TOKEN_STORAGE_KEY';
export const REPLICA_CHAT_NAME = 'REPLICA_CHAT_NAME';
export const GOOGLE_API_KEY = 'AIzaSyA8dr8ON9jeDZyrimTqldjLtgUFCLEi4D4';
export const GOOGLE_API_DOMAIN = 'bot2-387508.firebaseapp.com';
export const HAS_ENTER_REPLICA = 'HAS_ENTER_REPLICA';
export const DEFAULT_AVATAR =
  'https://app.bot2.ai/bot2/avatar/bot2-default.jpeg';
