import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { PageContainer } from '@components/PageContainer';
import { useEffect } from 'react';
import useMediaQuery from '@hooks/useMediaQuery';
import { IconButton } from '@components/basic/IconButton';

const HOME_PATH = '/home';
// const GEN_PATH = '/generate';
const SEARCH_PATH = '/search';
const MINE_PATH = '/mine';

const Home = () => {
  const isDesktop = useMediaQuery('(min-width: 780px)');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        overflowX: 'hidden',
        width: '100vw',
        height: '100vh',
      }}
    >
      <PageContainer>
        <Outlet />
        <div
          style={{
            position: 'fixed',
            bottom: '0',
            width: isDesktop ? '1000px' : '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            paddingBottom: '3px',
            overflowX: 'hidden',
            justifyContent: 'space-around',
            zIndex: 4,
            height: '74px',
            background:
              'linear-gradient(to bottom, rgba(123, 41, 105,0.8), rgba(0,0,0,0.7))',
            borderRadius: '30px 30px 0 0',
          }}
        >
          <IconButton
            src={`https://app.bot2.ai/bot2/fe-static/zoom-${
              location.pathname === SEARCH_PATH ? 'orange' : 'white'
            }.png`}
            text="Search"
            isSelected={location.pathname === SEARCH_PATH}
            onClick={() => navigate(SEARCH_PATH)}
          ></IconButton>
          <IconButton
            src={`https://app.bot2.ai/bot2/fe-static/chat-${
              location.pathname === HOME_PATH ? 'orange' : 'white'
            }.png`}
            text="Adventure"
            isSelected={location.pathname === HOME_PATH}
            onClick={() => navigate(HOME_PATH)}
          ></IconButton>
          <IconButton
            src={`https://app.bot2.ai/bot2/fe-static/alien-${
              location.pathname === MINE_PATH ? 'orange' : 'white'
            }.png`}
            text="My Bots"
            isSelected={location.pathname === MINE_PATH}
            onClick={() => navigate(MINE_PATH)}
          ></IconButton>
        </div>
      </PageContainer>
    </div>
  );
};

export default Home;
