import { themeColor } from './consts';

interface ToastProps {
  content: string;
  duration?: number;
  complete?: Function;
}

interface Modal {
  title: string;
  content: string;
  cancelTitle: string;
  cancel: Function;
  confirmTitle: string;
  confirm: Function;
  hasInput: boolean;
}

export const vhs = {
  /** 使用方式
   *
   * vhs.showToast({
   *       content: "toast内容",
   *       duration: 2500,
   *       complete: function() {
   *           console.log("toast消失回调");
   *       }
   *   });
   */
  showToast: (toast: ToastProps) => {
    var createToastTemplate = function () {
      var x = document.getElementsByTagName('body')[0];
      var v = document.createElement('div');
      v.id = 'vhs-main-toast';
      v.style.visibility = 'hidden';
      // v.style.minWidth = "10%";
      v.style.maxWidth = '300px';
      v.style.left = '0';
      v.style.right = '0';
      v.style.marginLeft = 'auto';
      v.style.marginRight = 'auto';
      v.style.backgroundColor = '#333';
      v.style.color = '#fff';
      v.style.textAlign = 'center';
      v.style.borderRadius = '5px';
      v.style.padding = '10px';
      v.style.position = 'fixed';
      v.style.zIndex = '30';
      v.style.top = '35%';
      v.style.fontSize = '1.2em';
      x.appendChild(v);
    };

    // 删除toast模版
    const removeToast = () => {
      const v = document.getElementById('vhs-main-toast');
      // @ts-ignore
      v.parentNode?.removeChild(v);
    };

    let duration = 2000;
    if (toast.duration) {
      duration = toast.duration;
    }

    if (toast.content) {
      createToastTemplate();
      const x = document.getElementById('vhs-main-toast');
      // @ts-ignore
      x.innerHTML = toast.content;
      // @ts-ignore
      x.style.visibility = 'visible';
      setTimeout(() => {
        removeToast();
        if (toast.complete) {
          toast.complete();
        }
      }, duration);
    }
  },
  getValidMetaWidthScale: function () {
    let scale = 1;
    const metaTagList = document.getElementsByTagName('meta');
    let metaContentWidth = 0;
    for (var i = metaTagList.length - 1; i >= 0; i--) {
      var meta = metaTagList[i];
      var content = meta.getAttribute('content');
      if (
        content == null ||
        content.indexOf('width') === -1 ||
        content === 'null'
      )
        continue;
      var attributeList = content.split(',');
      for (var j = 0, len = attributeList.length; j < len; j++) {
        var attr = attributeList[j];
        if (attr.indexOf('width') === -1) {
          continue;
        }
        var widthArray = attr.split('=');
        metaContentWidth = parseInt(widthArray[widthArray.length - 1]);
      }
      if (metaContentWidth > 0) {
        break;
      }
    }
    var standardMetaWidth = 375.0;
    if (metaContentWidth !== 0) {
      scale = metaContentWidth / standardMetaWidth;
    }
    return scale;
  },

  showModal: (modal: Modal) => {
    const that = this;
    var createModalTemplate = function () {
      //@ts-ignore
      var scale = that?.getValidMetaWidthScale();

      var body = document.getElementsByTagName('body')[0];

      var bgCover = document.createElement('div');
      bgCover.id = 'vhs-modal-bg-cover';
      bgCover.style.backgroundColor = 'rgba(51, 51, 51, 0.85)';
      bgCover.style.width = '100%';
      bgCover.style.height = '100%';
      bgCover.style.position = 'fixed';
      bgCover.style.zIndex = '999';
      bgCover.style.top = '0';
      body.appendChild(bgCover);

      var warp = document.createElement('div');
      warp.id = 'vhs-modal-warp';
      warp.style.visibility = 'visible';
      warp.style.minWidth = '100px';
      warp.style.maxWidth = '400px';
      warp.style.backgroundColor = themeColor.primary;
      warp.style.color = themeColor.orange;
      warp.style.textAlign = 'center';
      warp.style.paddingTop = '10px';
      warp.style.position = 'fixed';
      warp.style.zIndex = '10';
      warp.style.left = '0';
      warp.style.right = '0';
      warp.style.marginLeft = 'auto';
      warp.style.marginRight = 'auto';
      warp.style.top = '44%';
      warp.style.fontSize = '15px';
      warp.style.borderStyle = 'inset';
      warp.style.borderColor = themeColor.secondary;
      warp.style.borderWidth = '3px';
      warp.style.borderRadius = '20px';
      bgCover.appendChild(warp);

      var topDiv = document.createElement('div');
      warp.appendChild(topDiv);

      var title = document.createElement('p');
      title.id = 'vhs-modal-title';
      title.style.fontSize = '20px';
      title.style.marginTop = '0px';
      title.style.padding = '10px';
      topDiv.appendChild(title);

      if (modal.hasInput) {
        var content = document.createElement('input');
        content.id = 'vhs-modal-content';
        content.style.padding = '10px';
        content.style.fontSize = '14px';
        content.style.borderStyle = 'inset';
        content.style.borderColor = themeColor.secondary;
        content.style.borderWidth = '3px';
        content.style.borderRadius = '20px';
        content.style.color = 'white';
        content.style.marginBottom = '20px';
        content.style.marginTop = '20px';
        topDiv.appendChild(content);
      }

      var actionDiv = document.createElement('div');
      actionDiv.id = 'vhs-modal-action';
      actionDiv.style.bottom = '9px';
      actionDiv.style.width = '100%';
      actionDiv.style.borderTop = `2px inset ${themeColor.orange}`;
      actionDiv.style.height = '41px';
      actionDiv.style.lineHeight = '41px';
      warp.appendChild(actionDiv);

      var cancel = document.createElement('div');
      cancel.id = 'vhs-modal-cancel';
      cancel.style.float = 'left';
      cancel.style.width = '50%';
      cancel.style.height = '41px';
      cancel.style.lineHeight = '41px';
      actionDiv.appendChild(cancel);

      var confirm = document.createElement('div');
      confirm.id = 'vhs-modal-confirm';
      confirm.style.float = 'right';
      confirm.style.width = '49%';
      confirm.style.height = '41px';
      confirm.style.lineHeight = '41px';
      confirm.style.borderLeft = `2px inset ${themeColor.orange}`;
      actionDiv.appendChild(confirm);
    };

    var removeModal = function () {
      var w = document.getElementById('vhs-modal-bg-cover');
      if (w) {
        w.parentNode?.removeChild(w);
      }
    };

    var fixModalPosition = function () {
      // @ts-ignore
      let scale = that?.getValidMetaWidthScale();
      const warpDiv = document.getElementById('vhs-modal-warp');
      let winOutHeight = window.outerHeight;
      if (warpDiv) {
        warpDiv.style.top =
          (winOutHeight * scale - warpDiv.clientHeight) / 2 + 'px';
      }
    };

    if (modal.content) {
      createModalTemplate();
      // default "提示", dynamic set modal title
      const title = document.getElementById('vhs-modal-title');
      if (title) {
        title.innerText = modal.title || 'warning';
      }

      const content = document.getElementById('vhs-modal-content');
      if (content) {
        content.innerText = modal.content;
      }

      const cancel = document.getElementById('vhs-modal-cancel');
      if (cancel) {
        cancel.innerHTML = modal.cancelTitle || 'cancel';
      }

      // position fix to window center
      fixModalPosition();
      if (cancel) {
        cancel.onclick = () => {
          if (modal.cancel) {
            modal.cancel();
          }
          removeModal();
        };
      }

      const confirm = document.getElementById('vhs-modal-confirm');
      if (confirm) {
        confirm.innerHTML = modal.confirmTitle || 'confirm';
        confirm.onclick = function () {
          const inputValue =
            // @ts-ignore
            document.getElementById('vhs-modal-content')?.value;
          if (modal.confirm) modal.confirm(inputValue);
          removeModal();
        };
      }
    }
  },
};
