import useMediaQuery from '@hooks/useMediaQuery';
import { ReactNode, useEffect, useState } from 'react';
import { themeColor } from 'utils/consts';
const CustomPopup = ({
  show,
  title,
  children,
  onClose,
  preventBubble,
}: {
  show: boolean;
  children: ReactNode;
  title: string;
  onClose?: (val: boolean) => void;
  preventBubble?: boolean;
}) => {
  const [innerShow, setInnerShow] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 780px)');
  const closeHandler = () => {
    setInnerShow(false);
    onClose?.(false);
  };

  useEffect(() => {
    setInnerShow(show);
  }, [show]);

  return (
    <div
      style={{
        visibility: innerShow ? 'visible' : 'hidden',
        opacity: innerShow ? '1' : '0',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'rgba(0, 0, 0, 0.7)',
        transition: 'opacity 500ms',
        zIndex: 40,
      }}
      onClick={preventBubble ? undefined : closeHandler}
    >
      <div
        style={{
          margin: '30vh auto',
          padding: '10px',
          background: themeColor.secondary,
          borderRadius: '20px',
          minWidth: '300px',
          maxWidth: isDesktop ? '600px' : '300px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // transition: 'all 5s ease-in-out',
        }}
      >
        <div style={{ color: 'white', marginTop: '20px' }}>{title}</div>
        <span
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            transition: 'all 200ms',
            fontSize: '20px',
            fontWeight: 'bold',
            textDecoration: 'none',
            color: 'white',
            cursor: 'pointer',
          }}
          onClick={closeHandler}
        >
          &times;
        </span>
        <div
          style={{
            marginTop: '20px',
            overflow: 'auto',
            textAlign: 'center',
            lineHeight: '24px',
            maxWidth: isDesktop ? '600px' : '300px',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default CustomPopup;
