import { getAuth, signInWithPopup, TwitterAuthProvider } from 'firebase/auth';

import { initializeApp } from 'firebase/app';
import { GOOGLE_API_DOMAIN, GOOGLE_API_KEY } from './consts';
import { LoginResFlag, LoginType } from '@api/interface/auth';
import { login } from '@api/authApi';
import { vhs } from './vhs';

const firebaseConfig = {
  apiKey: GOOGLE_API_KEY,
  authDomain: GOOGLE_API_DOMAIN,
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const provider = new TwitterAuthProvider();

export const signInWithTwitter = async (): Promise<any> => {
  try {
    const result = await signInWithPopup(auth, provider);
    // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
    // You can use these server side with your app's credentials to access the Twitter API.
    const credential = TwitterAuthProvider.credentialFromResult(result);
    const token = credential?.accessToken;
    const secret = credential?.secret;
    if (!token || !secret) {
      return false;
    }

    const loginRes = await login({
      accessToken: token,
      loginType: LoginType.LOGIN_TYPE_TWITTER,
      secret: secret,
    });

    if (loginRes === LoginResFlag.LOGIN_SUCC) {
      return true;
    }

    if (loginRes === LoginResFlag.LOGIN_ACCESS_CODE_EMPTY) {
      const myPromise = new Promise((resolve, reject) => {
        vhs.showModal({
          title: 'please Input early access code',
          content: 'Content',
          hasInput: true,
          cancelTitle: 'cancel',
          cancel: () => {
            reject();
          },
          confirmTitle: 'Confirm',
          confirm: async (earlyAccess: string) => {
            const loginRes = await login({
              accessToken: token,
              loginType: LoginType.LOGIN_TYPE_TWITTER,
              secret: secret,
              earlyAccessCode: earlyAccess,
            });
            resolve(loginRes);
          },
        });
      });

      return myPromise;
    }

    return false;
  } catch (error: any) {
    // IdP data available using getAdditionalUserInfo(result)
    // ...
    // Handle Errors here.
    const errorCode = error?.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = TwitterAuthProvider.credentialFromError(error);
    return false;
  }
};
