import { AUTH_TOKEN_STORAGE_KET } from './consts';

export const checkAuthExist = (): boolean => {
  const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KET);
  if (!token) {
    return false;
  }
  return true;
};

export const logout = (): void => {
  localStorage.removeItem(AUTH_TOKEN_STORAGE_KET);
};
