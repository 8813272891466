import { deleteRobotAPI, getMyRobotsAPI } from '@api/robertApi';
import { Fragment, useEffect, useState } from 'react';
import { Robot, RobotType } from '@api/interface/robertInterface';
import { Button } from '@components/basic/Button';
import { useNavigate } from 'react-router-dom';
import CustomPopup from '@components/PopUp';
import { Loading } from '@components/Loading';
import { Typography, Variant } from '@components/basic/Typography';
import { BotItem } from '@components/BotItem';
import { PersonalCenter } from '@components/PersonalCenter';
import useMediaQuery from '@hooks/useMediaQuery';
import { vhs } from 'utils/vhs';
interface PopWording {
  title: string;
  text: string;
}

export const MyAIRepo = () => {
  const [robots, setRobots] = useState<Robot[]>();
  const [showPopUp, setShowPopUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [replicaExits, setReplicaExits] = useState(false);
  const [popWording, setPopWording] = useState<PopWording>({
    title: '',
    text: '',
  });
  const navigate = useNavigate();
  const getMyRobots = async () => {
    const res = await getMyRobotsAPI(RobotType.ROBOT_TYPE_NORMAL);
    await getMyReplica();
    setLoading(false);
    setRobots(res?.robots || []);
  };

  const getMyReplica = async () => {
    const res = await getMyRobotsAPI(RobotType.ROBOT_TYPE_REPLICA);
    if (!!res?.robots.length) {
      setReplicaExits(true);
    }
  };

  const deleteAI = async (robotId: string) => {
    vhs.showModal({
      title: 'Are you sure you want to delete the bot?',
      content: 'x',
      cancelTitle: 'Cancel',
      cancel: () => {},
      confirmTitle: 'Confirm',
      confirm: async () => {
        const res = await deleteRobotAPI({
          robotId,
        });
        if (res) {
          setShowPopUp(true);
          setPopWording({ title: 'Success', text: 'delete success!' });
        } else {
          setShowPopUp(true);
          setPopWording({ title: 'Error', text: 'delete failed!' });
        }
        getMyRobots();
      },
      hasInput: false,
    });
  };

  useEffect(() => {
    getMyRobots();
  }, []);
  const isDesktop = useMediaQuery('(min-width: 780px)');
  return (
    <div
      style={{
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        scrollbarWidth: 'none',
        width: '100%',
        position: 'relative',
        marginBottom: '200px',
      }}
    >
      {isDesktop && (
        <PersonalCenter
          style={{ position: 'absolute', right: '10px', top: '10px' }}
        ></PersonalCenter>
      )}
      <CustomPopup
        title={popWording.title}
        show={showPopUp}
        onClose={() => {
          setShowPopUp(false);
        }}
      >
        {popWording.text}
      </CustomPopup>
      <Typography variant={Variant.H1} style={{ marginTop: '20px' }}>
        My Bots Repo
      </Typography>
      {/* {loading } */}

      {loading ? (
        <div
          style={{
            height: '400px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loading></Loading>
        </div>
      ) : (
        <Fragment>
          {!!robots?.length ? (
            <span></span>
          ) : (
            <Typography variant={Variant.H2}>
              「 You don't have any AI Yet 」
            </Typography>
          )}
          <div
            style={{
              width: '100%',
              margin: '20px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'flexStart',
              position: 'relative',
              flexWrap: 'wrap',
              gap: '10px 20px',
            }}
          >
            {robots?.map((item) => {
              return (
                <BotItem
                  key={item.basic?.robotId}
                  item={item}
                  deleteAI={() => deleteAI(item?.basic?.robotId || '')}
                  editAI={() => {
                    navigate(
                      `/generate/${item?.basic?.robotId || ''}?botType=1`
                    );
                  }}
                ></BotItem>
              );
            })}
          </div>
        </Fragment>
      )}

      <div
        style={{
          position: 'relative',
        }}
      >
        <Button
          onClick={() => {
            navigate('/generate?botType=1');
          }}
        >
          Generate new
        </Button>
      </div>
      <div
        style={{
          position: 'relative',
          marginTop: '30px',
        }}
      >
        <Button
          onClick={() => {
            if (replicaExits) {
              navigate('/replica');
            } else {
              navigate('/replica/intro');
            }
          }}
        >
          <div>
            My Replica <span style={{ fontSize: '12px' }}>(Beta)</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default MyAIRepo;
