import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserData {
  nick: string;
  avatar: string;
  email: string;
  userId: string;
  loginType: number;
  score: number;
  inviteCode: string;
}

const initialState: UserData = {
  nick: '',
  avatar: '',
  email: '',
  userId: '',
  loginType: 0,
  score: 0,
  inviteCode: '',
};

export const globalDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserData>) => {
      const { nick, avatar, email, userId, loginType, score, inviteCode } =
        action.payload;
      state.nick = nick;
      state.avatar = avatar;
      state.email = email;
      state.userId = userId;
      state.loginType = loginType;
      state.score = score;
      state.inviteCode = inviteCode;
    },
    updateScore: (state, action: PayloadAction<number>) => {
      state.score = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserData, updateScore } = globalDataSlice.actions;

export default globalDataSlice.reducer;
