import { RedoOutlined } from '@ant-design/icons';
import { Robot } from '@api/interface/robertInterface';
import { getRecommendedRobotsAPI, searchRobotAPI } from '@api/robertApi';
import { BotItem, buttonStyle } from '@components/BotItem';
import { Loading } from '@components/Loading';
import { PersonalCenter } from '@components/PersonalCenter';
import { Typography, Variant } from '@components/basic/Typography';
import useMediaQuery from '@hooks/useMediaQuery';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { themeColor } from 'utils/consts';

const Redo = styled(RedoOutlined)`
  margin-left: 20px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  &:hover {
    transform: scale(1.2);
  }
  &:active {
    transform: scale(1);
  }
`;

export const Search = () => {
  const [searchAID, setSearchAID] = useState('');
  const [recommendedAIs, setRecommendedAIs] = useState<Robot[]>([]);
  const [searchedAI, setSearchedAI] = useState<Robot>();
  const isDesktop = useMediaQuery('(min-width: 780px)');
  const [loading, setLoading] = useState(true);
  const clear = () => {
    setSearchAID('');
    setSearchedAI(undefined);
  };
  const searchAI = async () => {
    setSearchedAI(undefined);
    setLoading(true);
    const res = await searchRobotAPI(searchAID);
    if (res?.robot) {
      setSearchedAI(res.robot);
    }
    setLoading(false);
  };

  const getAIs = async () => {
    setLoading(true);
    const res = await getRecommendedRobotsAPI();
    setRecommendedAIs(res?.robots || []);
    setLoading(false);
  };
  useEffect(() => {
    getAIs();
  }, []);
  return (
    <div
      style={{
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'scroll',
        width: '100%',
      }}
    >
      {isDesktop && (
        <PersonalCenter
          style={{ position: 'absolute', right: '10px', top: '10px' }}
        ></PersonalCenter>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography variant={Variant.H1}> Search bot by ID</Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '10px',
            width: isDesktop ? '440px' : undefined,
          }}
        >
          <input
            style={{
              backgroundColor: themeColor.primary,
              borderRadius: '10px',
              borderStyle: 'inset',
              borderWidth: '2px',
              marginRight: '10px',
              minHeight: '30px',
              minWidth: '58%',
              padding: '20px',
            }}
            value={searchAID}
            onChange={(e) => {
              setSearchAID(e.target.value);
            }}
          ></input>
          <div
            style={buttonStyle}
            onClick={() => {
              searchAI();
            }}
          >
            Search
          </div>
          <div
            style={buttonStyle}
            onClick={() => {
              clear();
            }}
          >
            Clear
          </div>
        </div>
        <hr
          style={{
            marginTop: '30px',
            width: '90%',
            borderColor: themeColor.primary,
          }}
        />
      </div>
      {loading ? (
        <div
          style={{
            height: '400px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loading></Loading>
        </div>
      ) : searchedAI ? (
        <div
          style={{
            marginBottom: '150px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant={Variant.H1}>Search result</Typography>
          <BotItem item={searchedAI}></BotItem>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: isDesktop ? '80%' : '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: isDesktop ? '80%' : '100%',
            }}
          >
            <Typography variant={Variant.H2}>
              Bots with best performance
            </Typography>
            <Redo
              onClick={() => {
                getAIs();
              }}
            />
          </div>
          <div
            style={{
              marginTop: '30px',
              marginBottom: '150px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'space-around',
              width: '100%',
              flexWrap: 'wrap',
              gap: '10px 20px',
            }}
          >
            {!!recommendedAIs.length &&
              recommendedAIs.map((item) => {
                return (
                  <BotItem item={item} key={item.basic?.robotId}></BotItem>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;
