import { ReactNode } from 'react';

export enum Variant {
  H1,
  H2,
  H3,
  Body,
}

const fontSizeConvert = (
  variant: Variant,
  fontFamily: string
): React.CSSProperties => {
  switch (variant) {
    case Variant.H1:
      return {
        fontSize: '24px',
        margin: '20px 0',
        fontWeight: 'bold',
        fontFamily,
        textShadow: '2px 2px 0px #8a5f1c',
      };
    case Variant.H2:
      return {
        fontSize: '18px',
        margin: '15px 0',
        fontWeight: 'bold',
        fontFamily,
        textShadow: '2px 2px 0px #8a5f1c',
      };
    case Variant.H3:
      return {
        fontSize: '16px',
        margin: '15px 0',
        fontWeight: 'bold',
        fontFamily,
        textShadow: '2px 2px 0px #8a5f1c',
      };
    case Variant.Body:
      return { fontSize: '13px' };
    default:
      return { fontSize: '12px' };
  }
};

export const Typography = ({
  variant,
  fontFamily,
  style,
  children,
  className,
}: {
  variant: Variant;
  style?: React.CSSProperties;
  fontFamily?: string;
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      style={{ ...fontSizeConvert(variant, fontFamily ?? 'space'), ...style }}
      className={className}
    >
      {children}
    </div>
  );
};

export const Body = () => {};
