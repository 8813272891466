import { LoginButton } from '@components/LoginButton';
import { PageContainer } from '@components/PageContainer';
import useMediaQuery from '@hooks/useMediaQuery';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { vhs } from 'utils/vhs';
import { useEffect } from 'react';
import { AUTH_TOKEN_STORAGE_KET } from 'utils/consts';
import { useAppDispatch } from '@store/store';
import { setUserData } from '@store/userDataSlice';
import { getUserInfo } from '@api/userInfoApi';
import { signInWithTwitter } from 'utils/twitterAuth';
import { RainbowButton } from '@components/RainbowButton';
import { signInToGoogle } from 'utils/googleCloudSignIn';
interface LogoProps {
  isDesktop: boolean;
}
const Logo = styled.img<LogoProps>`
  width: ${(props) => (props.isDesktop ? '200px' : '100px')};
  margin-bottom: 8%;
  margin-top: 8%;
`;

const Divider = styled.div`
  width: 30%;
  height: 2px;
  background-color: gray;
`;
export const Login = () => {
  const isDesktop = useMediaQuery('(min-width: 780px)');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KET);
    // if token exist, then jump to home page
    if (token) {
      navigate('/home');
    }
  }, []);
  const loginViaGoogle = async () => {
    try {
      const res = await signInToGoogle();
      if (res) {
        getUser();
        navigate('/home');
      } else {
        vhs.showToast({ content: 'login Failed', duration: 3000 });
      }
    } catch (e) {
      vhs.showToast({ content: 'login Failed', duration: 3000 });
    }
  };

  const loginViaTwitter = async () => {
    try {
      const res = await signInWithTwitter();
      if (res) {
        getUser();
        navigate('/home');
      } else {
        vhs.showToast({ content: 'login Failed', duration: 3000 });
      }
    } catch (e) {
      vhs.showToast({ content: 'login Failed', duration: 3000 });
    }
  };

  const getUser = async () => {
    const userInfo = await getUserInfo();
    dispatch(
      setUserData({
        nick: userInfo?.user?.nick || '',
        avatar: userInfo?.user?.avatar || '',
        email: userInfo?.user?.email || '',
        userId: userInfo?.user?.userId || '',
        loginType: userInfo?.user?.loginType || 0,
        score: userInfo?.user?.dynamic?.score || 0,
        inviteCode: userInfo?.user?.inviteCode || '',
      })
    );
  };
  return (
    <PageContainer blur>
      <div style={{ height: '100vh', overflow: 'hidden' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            marginTop: '40px',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Logo
            isDesktop={isDesktop}
            src="https://app.bot2.ai/bot2/fe-static/logo.png"
            alt=""
          />
          <div
            style={{
              marginBottom: '8%',
              fontFamily: 'space',
              fontSize: '20px',
              textAlign: 'center',
              lineHeight: '30px',
              maxWidth: '80%',
            }}
          >
            AI & WEB3 BASED GAMIFICATION UGC PLATFORM
          </div>

          <RainbowButton></RainbowButton>

          <div
            style={{
              marginTop: '8%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Divider></Divider>
            <div style={{ margin: '0 5%' }}>Or connect using</div>
            <Divider></Divider>
          </div>
          <div
            style={{
              marginTop: '8%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <LoginButton
              text="Google"
              src={'https://app.bot2.ai/bot2/fe-static/GoogleIcon.png'}
              onClick={() => loginViaGoogle()}
              style={{ width: '40%', marginRight: '5%' }}
            ></LoginButton>

            <LoginButton
              text="Twitter"
              src={'https://app.bot2.ai/bot2/fe-static/twitter.png'}
              onClick={() => loginViaTwitter()}
              style={{ width: '40%' }}
            ></LoginButton>
          </div>
          <div
            style={{
              fontFamily: 'space',
              fontSize: '0.9em',
              textAlign: 'center',
              lineHeight: '20px',
              maxWidth: '400px',
              marginTop: '8%',
              color: 'rgba(255,255,255,0.75)',
            }}
          >
            If you haven't registered before, we will help you create an account
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Login;
