import { login } from '@api/authApi';
import { LoginResFlag, LoginType } from '@api/interface/auth';

import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { GOOGLE_API_DOMAIN, GOOGLE_API_KEY } from './consts';
import { vhs } from './vhs';

const firebaseConfig = {
  apiKey: GOOGLE_API_KEY,
  authDomain: GOOGLE_API_DOMAIN,
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export const signInToGoogle = async (): Promise<boolean | unknown> => {
  try {
    const result = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential?.accessToken;
    // The signed-in user info.
    if (!token) {
      return false;
    }

    const loginRes = await login({
      accessToken: token,
      loginType: LoginType.LOGIN_TYPE_GOOGLE,
    });

    if (loginRes === LoginResFlag.LOGIN_SUCC) {
      return true;
    }

    if (loginRes === LoginResFlag.LOGIN_ACCESS_CODE_EMPTY) {
      const myPromise = new Promise((resolve, reject) => {
        vhs.showModal({
          title: 'please Input early access code',
          content: 'Content',
          cancelTitle: 'Cancel',
          hasInput: true,
          cancel: () => {
            reject();
          },
          confirmTitle: 'Confirm',
          confirm: async (earlyAccess: string) => {
            const loginRes = await login({
              accessToken: token,
              loginType: LoginType.LOGIN_TYPE_GOOGLE,
              earlyAccessCode: earlyAccess,
            });
            resolve(loginRes === LoginResFlag.LOGIN_SUCC);
          },
        });
      });

      return myPromise;
    }

    return false;
  } catch (error: any) {
    const errorCode = error?.code;
    const errorMessage = error?.message;
    // The email of the user's account used.
    const email = error?.customData?.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    return false;
  }
};
