import {
  guessAPI,
  sendMessageAPI,
  waitGuessAPI,
  waitMessageAPI,
} from '@api/chatApi';
import { Message, UserType } from '@api/interface/chatInterface';
import { Button } from '@components/basic/Button';
import { Fragment, useEffect, useRef, useState } from 'react';
import { MessageItem } from './MessageItem';
import { TextInput } from './TextInput';
import { createNewChat } from '@api/chatApi';
import { PageContainer } from '@components/PageContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { vhs } from 'utils/vhs';
import styled from 'styled-components';
import useMediaQuery from '@hooks/useMediaQuery';
import { OperationPanel } from './OperationPanel';
import { Typography, Variant } from '@components/basic/Typography';
import { getUserInfo } from '@api/userInfoApi';
import { useAppDispatch } from 'store/store';
import { updateScore } from '@store/userDataSlice';
import { Header } from '@components/Header';
import { BotHorizontalCard } from '@components/BotHorizontalCard';
import CircularProgress from '@mui/material/CircularProgress';
interface WrapperProps {
  isDeskTop: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: ${(prop) => (prop.isDeskTop ? '660px' : '100%')};
`;

const sessionData = {
  userId: '',
  chatId: '',
  chatTime: 120,
  turnTime: 20,
  isMyTurn: false,
};

const Chat = () => {
  const isMounted = useRef(false);
  const activeRef = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  const navigate = useNavigate();
  // const sessionData = useAppSelector((state) => state.sessionData);
  const [isMyTurn, setIsMyTurn] = useState<boolean>(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [correct, setCorrect] = useState<boolean>(false);
  const [partnerType, setPartnerType] = useState<UserType>(-1);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [hasGuessed, setHasGuessed] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<number>(300);
  const [partnerName, setPartnerName] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [score, setScore] = useState(0);
  const [partnerAvatar, setPartnerAvatar] = useState('');
  let { id } = useParams();
  let messagesEnd: HTMLDivElement | null;

  const changeActive = (active: boolean) => {
    setIsActive(active);
    activeRef.current = active;
  };
  useEffect(() => {
    restartGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetState = () => {
    setIsMyTurn(true);
    setMessages([]);
    changeActive(true);

    setHasGuessed(false);
    setScore(0);
    setCountDown(300);
  };

  const restartGame = async () => {
    resetState();
    setLoading(true);
    const res = await createNewChat({
      robotId: id || '',
    });
    waitGuess(res?.info?.chatId || '');
    sessionData.userId = res?.info?.userId || '';
    sessionData.chatId = res?.info?.chatId || '';
    sessionData.chatTime = res?.info?.chatTime ?? sessionData.chatTime;
    sessionData.isMyTurn = res?.info?.isMyTurn ?? sessionData.isMyTurn;
    sessionData.turnTime = res?.info?.turnTime ?? sessionData.chatTime;
    setCountDown(sessionData.chatTime);
    setIsMyTurn(sessionData.isMyTurn);
    setPartnerName(res?.info?.partnerName || '');
    setPartnerAvatar(res?.info?.partnerAvatar || '');
    if (!res?.info?.isMyTurn) {
      const waitRes = await waitMessageAPI({
        chatId: sessionData.chatId || '',
      });
      setMessages(waitRes?.info?.messages || messages);
      changeActive(waitRes?.info?.isActive ? waitRes?.info?.isActive : false);
      setIsMyTurn(true);
    }
    setLoading(false);
  };

  const waitGuess = async (chatId: string) => {
    const res = await waitGuessAPI(chatId);
    // 长轮训 被关闭 重新开启, 最多重试10次
    if (res && !res?.info && activeRef.current && isMounted.current) {
      waitGuess(chatId);
    }
    if (res?.info?.guessed) {
      setHasGuessed(true);
      changeActive(false);
      setScore(res?.info?.score || 0);
      setCorrect(!res?.info?.correct);
      if (res?.info?.correct) {
        vhs?.showToast({
          content:
            '❌ You are exposed<br/>The opponent has identified you as Human<br/>',
          duration: 5000,
        });
        changeActive(false);
      } else {
        vhs?.showToast({
          content: '✅ You win <br/>The opponent has made the wrong guess',
          duration: 5000,
        });
        changeActive(false);
      }
      if (res?.info?.score) {
        const userInfo = await getUserInfo();
        if (userInfo?.user?.dynamic?.score) {
          dispatch(updateScore(userInfo?.user?.dynamic?.score));
        }
      }
    }
  };

  const sendMessage = async (text: string) => {
    setIsMyTurn(false);
    setMessages([
      ...messages,
      { id: '', user: sessionData.userId || '', text },
    ]);
    const res = await sendMessageAPI({
      chatId: sessionData.chatId || '',
      text: text,
    });
    setMessages(res?.info?.messages || messages);
    setIsMyTurn(!!res?.info?.isMyTurn);
  };
  const dispatch = useAppDispatch();

  const guess = async (number: UserType) => {
    const res = await guessAPI({
      chatId: sessionData.chatId || '',
      partnerType: number,
    });
    setHasGuessed(true);
    changeActive(false);
    setScore(res?.score || 0);
    setPartnerType(res?.partnerType || -1);
    setCorrect(res?.correct ? res?.correct : false);
    const userInfo = await getUserInfo();
    if (userInfo?.user?.dynamic?.score) {
      dispatch(updateScore(userInfo?.user?.dynamic?.score));
    }
    window.scrollTo(0, document.body.scrollHeight);
  };
  // scroll to bottom
  useEffect(() => {
    if (messages.length > 6) {
      messagesEnd?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isActive]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
        if (countDown <= 1) {
          changeActive(false);
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  });
  const isDesktop = useMediaQuery('(min-width: 780px)');
  return (
    <PageContainer blur>
      <Header
        style={{ position: 'sticky', top: 0 }}
        title={
          id
            ? partnerName || 'Test Your AI'
            : `Bot or Human ${!hasGuessed ? `${countDown} S` : '- End'}`
        }
        onBack={() => {
          changeActive(false);
          if (id) {
            navigate('/search');
          } else {
            navigate('/home');
          }
        }}
      ></Header>

      {loading ? (
        <div
          style={{
            height: '800px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress style={{ color: 'white', width: 80, height: 80 }} />
        </div>
      ) : (
        <Fragment>
          {!id && (
            <OperationPanel
              guess={guess}
              canGuess={messages.length > 5}
              hasGuessed={hasGuessed}
              guessInfo={{
                win: correct,
                partnerType,
                score,
                chatId: sessionData.chatId,
              }}
              restart={restartGame}
            ></OperationPanel>
          )}
          <Wrapper isDeskTop={isDesktop}>
            <div
              style={{
                width: '95%',
                minHeight: '100vh',
                marginBottom: '80px',
              }}
            >
              <div>
                {!!id && !!partnerName && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <BotHorizontalCard
                      avatar={partnerAvatar}
                      id={id}
                      name={partnerName}
                    ></BotHorizontalCard>
                  </div>
                )}
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  overflowX: 'hidden',
                }}
              >
                {/* 聊天框 */}
                {messages.map((item, index) => {
                  return (
                    <MessageItem
                      key={index}
                      message={item}
                      partnerAvatar={partnerAvatar}
                      isMyMessage={item.user === sessionData.userId}
                      showThought={!!id}
                    ></MessageItem>
                  );
                })}
                {/* 输入框 */}
                {!hasGuessed && isActive && (
                  <TextInput
                    isMyTurn={isMyTurn}
                    partnerAvatar={partnerAvatar}
                    sendMessage={sendMessage}
                  ></TextInput>
                )}
                {!!id && !isActive && (
                  <div
                    style={{
                      position: 'relative',
                      marginTop: '20px',
                    }}
                  >
                    <Typography variant={Variant.H1}>session end!</Typography>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{ float: 'left', clear: 'both' }}
              ref={(el) => {
                messagesEnd = el;
              }}
            ></div>
          </Wrapper>
        </Fragment>
      )}
    </PageContainer>
  );
};

export default Chat;
