import useMediaQuery from '@hooks/useMediaQuery';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  blur?: boolean;
};

export const PageContainer = ({ children, blur }: Props) => {
  const isDesktop = useMediaQuery('(min-width: 780px)');
  const backgroundImg = isDesktop
    ? 'https://app.bot2.ai/bot2/fe-static/background-9.jpeg'
    : 'https://app.bot2.ai/bot2/fe-static/background-9-mobile.jpeg';
  return (
    <div
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
      }}
    >
      <img
        src={backgroundImg}
        alt=""
        style={{
          filter: blur ? 'blur(6px)' : undefined,
          height: '100vh',
          width: '100%',
          position: 'absolute',
          objectFit: 'cover',
          left: 0,
          top: 0,
          zIndex: -1,
        }}
      />
      <div
        style={{
          height: '100vh',
          width: '100vw',
          position: 'absolute',
          left: 0,
          top: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: -1,
        }}
      ></div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        {children}
      </div>
    </div>
  );
};
