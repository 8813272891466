import { Button } from '@components/basic/Button';
import { PersonalCenter } from '@components/PersonalCenter';
import { Typography, Variant } from '@components/basic/Typography';
import useMediaQuery from '@hooks/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { debounce } from 'utils';

const paraFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Paragraph = styled(Typography)`
  margin-top: 30px;
  max-width: 550px;
  line-height: 40px;
  text-align: center;
  animation: ${paraFadeIn} 2000ms both;
  animation-delay: 1000ms;
  font-family: space;
`;

const AdventureButton = styled(Button)`
  animation: ${paraFadeIn} 2000ms both;
  animation-delay: 1500ms;
`;

interface LogoProps {
  isDesktop: boolean;
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Logo = styled.img<LogoProps>`
  width: ${(props) => (props.isDesktop ? '200px' : '100px')};
  margin-bottom: 22%;
  animation: ${fadeIn} 1500ms backwards;
  margin-top: 50px;
`;

export const BackgroundStory = () => {
  const navigate = useNavigate();
  const start = async () => {
    navigate('/chat');
  };
  const isDesktop = useMediaQuery('(min-width: 780px)');
  return (
    <div style={{ height: '100vh', overflow: 'hidden' }}>
      <PersonalCenter
        style={{ position: 'absolute', right: '10px', top: '10px' }}
      ></PersonalCenter>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          marginTop: '40px',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Logo
          isDesktop={isDesktop}
          src="https://app.bot2.ai/bot2/fe-static/logo.png"
          alt=""
        />
        <Paragraph variant={Variant.H2}>
          1. Randomly match with someone
        </Paragraph>
        <Paragraph variant={Variant.H2}>2. Chat for several rounds</Paragraph>
        <Paragraph variant={Variant.H2}>
          3. Figure out if it was an Bot or Human
        </Paragraph>
        <div
          style={{ marginTop: '18%', display: 'flex', flexDirection: 'row' }}
        ></div>
        <AdventureButton onClick={debounce(() => start())}>
          Start Adventure
        </AdventureButton>
        <div style={{ height: '30px' }}></div>
        <AdventureButton
          onClick={() => {
            navigate('/leaderboard');
          }}
        >
          Leaderboard
        </AdventureButton>
      </div>
    </div>
  );
};

export default BackgroundStory;
