import { ReactNode } from 'react';
import styled from 'styled-components';

const Span = styled.span`
  display: block;
  padding: 20px 20px;
  border-radius: 12px;
  font-size: 1.25rem;
  background: #7b2969;
  font-family: space, space;
  color: white;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
`;
const LargeButton = styled.button`
  border: none;
  border-radius: 12px;
  padding: 0;
  cursor: pointer;
  background: linear-gradient(180deg, #7b2969, #471d4b 10%);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
  &:hover ${Span} {
    transform: translateY(-10px);
    transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  }
  &:active ${Span} {
    transform: translateY(-2px);
    transition: transform 34ms;
  }
`;

export const Button = ({
  onClick,
  children,
  style,
  className,
}: {
  onClick?: Function;
  children?: ReactNode;
  style?: React.CSSProperties;
  className?: string;
}) => {
  return (
    <LargeButton
      className={className}
      onClick={() => {
        onClick?.();
      }}
    >
      <Span style={style}>{children}</Span>
    </LargeButton>
  );
};
