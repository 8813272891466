import { KAttachType } from '@api/interface/knowledgeInterface';
import useMediaQuery from '@hooks/useMediaQuery';
import { FileType } from 'interface';
import { Fragment, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const Viewer = styled.div`
  z-index: 100;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MediaViewer = ({
  src,
  style,
  alt,
  type,
}: {
  src: string;
  style?: React.CSSProperties;
  alt?: string;
  type: KAttachType;
}) => {
  const [show, setShow] = useState(false);

  return (
    <Fragment>
      {type === KAttachType.k_ATTACH_TYPE_VIDEO ? (
        <video
          src={src}
          style={style}
          onClick={() => {
            setShow(true);
          }}
        ></video>
      ) : (
        <img
          src={src}
          style={style}
          alt={alt ?? ''}
          onClick={() => {
            setShow(true);
          }}
        ></img>
      )}

      {createPortal(
        <Preview
          src={src}
          show={show}
          close={() => {
            setShow(false);
          }}
          type={type}
        ></Preview>,
        document.body
      )}
    </Fragment>
  );
};

const Preview = ({
  src,
  show,
  close,
  type,
}: {
  src: string;
  show: boolean;
  close: Function;
  type: KAttachType;
}) => {
  const isDesktop = useMediaQuery('(min-width: 780px)');
  if (!show) {
    return null;
  }
  return (
    <Viewer onClick={() => close()}>
      {type === KAttachType.k_ATTACH_TYPE_VIDEO ? (
        <video
          src={src}
          controls
          style={{
            width: isDesktop ? undefined : '80%',
            height: isDesktop ? '80%' : undefined,
          }}
        />
      ) : (
        <img
          src={src}
          alt=""
          style={{
            width: isDesktop ? undefined : '80%',
            height: isDesktop ? '80%' : undefined,
          }}
        />
      )}
    </Viewer>
  );
};
