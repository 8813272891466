import { UserType } from '@api/interface/chatInterface';
import { Button, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import { debounce } from 'utils';
import { themeColor } from 'utils/consts';
import { Fragment } from 'react';
import { shareOnTwitter } from 'utils/shareOnTwitter';
import IosShareIcon from '@mui/icons-material/IosShare';
import { getUserInfo } from '@api/userInfoApi';
import { updateScore } from '@store/userDataSlice';
import { shareAndEarn } from '@api/chatApi';
import { vhs } from 'utils/vhs';
import { useAppDispatch } from '@store/store';

const Outer = styled.div`
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  position: sticky;
  z-index: 20;
  top: 65px;
`;

const Wrapper = styled.div`
  padding: 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  position: relative;
  color: white;
  border-color: ${themeColor.primary};
  background-color: rgba(0, 0, 0, 0.8);
  border-color: ${themeColor.primary};
  box-shadow: 3px 3px 8px 2px ${themeColor.primaryFadeMore};
`;

interface GuessInfo {
  win: boolean;
  partnerType: number;
  score: number;
  chatId: string;
}

export const OperationPanel = ({
  guess,
  canGuess,
  hasGuessed,
  guessInfo,
  restart,
}: {
  guess: Function;
  canGuess: boolean;
  hasGuessed: boolean;
  guessInfo: GuessInfo;
  restart: Function;
}) => {
  const cannotGuess = () => {
    vhs.showToast({
      content: 'You can guess after chatting for several rounds',
    });
  };
  const isDesktop = useMediaQuery('(min-width: 780px)');

  return (
    <Outer>
      {hasGuessed ? (
        <GuessResult
          win={guessInfo.win}
          partnerType={guessInfo.partnerType}
          score={guessInfo.score}
          chatId={guessInfo.chatId}
          restart={restart}
        ></GuessResult>
      ) : (
        <Wrapper
          style={{
            opacity: canGuess ? undefined : '0.5',
            margin: isDesktop ? '20px 0' : '0 0',
            width: isDesktop ? undefined : '100%',
          }}
          onClick={canGuess ? undefined : cannotGuess}
        >
          <div style={{ marginRight: '20px' }}> Make Your Guess</div>
          <Button
            onClick={debounce(() => guess(UserType.USER_TYPE_HUMAN))}
            variant="contained"
            disabled={!canGuess}
            sx={{ marginRight: '20px' }}
          >
            Human
          </Button>
          <Button
            disabled={!canGuess}
            onClick={debounce(() => guess(UserType.USER_TYPE_AI))}
            variant="contained"
          >
            AI
          </Button>
        </Wrapper>
      )}
    </Outer>
  );
};

export const GuessResult = ({
  win,
  partnerType,
  score,
  chatId,
  restart,
}: {
  win: boolean;
  partnerType: UserType;
  score: number;
  chatId: string;
  restart: Function;
}) => {
  const dispatch = useAppDispatch();
  const share = async () => {
    shareOnTwitter();
    const res = await shareAndEarn(chatId);
    if (res) {
      setTimeout(async () => {
        const userInfo = await getUserInfo();
        vhs.showToast({
          content: 'Successfully shared to twitter! extra points earned',
        });
        if (userInfo?.user?.dynamic?.score) {
          dispatch(updateScore(userInfo?.user?.dynamic?.score));
        }
      }, 20000);
    }
  };

  return (
    <Fragment>
      <Wrapper style={{ flexDirection: 'column' }}>
        {win && (
          <div style={{ color: themeColor.orange }}>✅ congrats! You Win</div>
        )}
        {!win && <div>❌ Sorry! You Lost </div>}
        {(partnerType === UserType.USER_TYPE_AI ||
          partnerType === UserType.USER_TYPE_HUMAN) && (
          <Fragment>
            <div style={{ marginTop: '20px', fontSize: '18px' }}>
              You just talked to
            </div>
            <div
              style={{
                marginTop: '10px',
                fontSize: '18px',
              }}
            >
              {partnerType === UserType.USER_TYPE_AI
                ? 'A BOT'
                : 'AN HUMAN FELLOW'}
            </div>
          </Fragment>
        )}
        {!!score && (
          <div style={{ marginTop: '10px' }}>
            You earned{' '}
            <span style={{ color: themeColor.orange }}>{score} points</span>
          </div>
        )}
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            variant="outlined"
            endIcon={<IosShareIcon />}
            size="small"
            onClick={() => share()}
          >
            Share
          </Button>
          <div style={{ marginTop: '15px', fontSize: '14px' }}>
            Share on twitter to
            <span style={{ color: themeColor.orange, marginLeft: '5px' }}>
              win extra 5 points!
            </span>
          </div>

          <Button
            variant="contained"
            size="large"
            onClick={() => restart()}
            sx={{ marginTop: '20px' }}
          >
            Restart
          </Button>
        </div>
      </Wrapper>
    </Fragment>
  );
};
